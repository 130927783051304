import {
	ChangeDetectorRef,
	Component,
	Input,
	NgZone,
	OnInit,
	Optional,
	QueryList,
	ViewChild,
	ViewChildren,
	ViewContainerRef,
} from "@angular/core";
import { Path } from "@progress/kendo-drawing";
import { GridSettings } from "../../_models/grid-settings.interface";
import { FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";

import _, { max } from "lodash";
import { filter } from "rxjs/operators";
import { process, State } from "@progress/kendo-data-query";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { GseService } from "../../services/gse.service";
import { UtilityService } from "../../services/utility.service";
import { DashboardService } from "../../services/dashboard.service";
import { IGlobal } from "../../_models/global.model";
import { Global } from "../../_constants/global.variables";
import { MatStepper } from "@angular/material/stepper";
import { SassHelperComponent } from "../../_helpers/sass-helper/sass-helper.component";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { ThemeService } from "@progress/kendo-angular-gauges";
import { MatDialog } from "@angular/material/dialog";

import { BreadCrumbsEventsTemplatePopupComponent } from "../breadcrumbs-events-template-popup/breadcrumbs-events-template-popup.component";

import { DialogModalParentComponent } from "../dialog-modal-parent/dialog-modal-parent.component";

import {
	CompactType,
	DisplayGrid,
	GridsterComponentInterface,
	GridsterItem,
	GridsterItemComponentInterface,
	GridType,
} from "angular-gridster2";
import { MapsService } from "../../services/maps.service";
import { ChartService } from "../../services/chart.service";
import { DecimalPlacesPipe } from "../../_helpers/pipes/decimal-places.pipe";
import { time } from "console";

declare var Highcharts: any;

declare const google: any;
declare const markerClusterer: any;

@Component({
	selector: "lib-breadcrumbs",
	templateUrl: "./breadcrumbs.component.html",
	styleUrls: ["./breadcrumbs.component.scss"],
})
export class BreadcrumbsComponent implements OnInit {
	getObservationsForDefaultList: boolean = true;

	favoriteSeason: string;
	seasons: string[] = ["Winter", "Spring", "Summer", "Autumn"];
	public materialTabSelected: number = 0;

	@ViewChild("alarmDataGrid") alarmDataGrid: KendoGridParentComponent;
	@ViewChild("alertDataGrid") alertDataGrid: KendoGridParentComponent;

	@ViewChild(SassHelperComponent)
	private sassHelper: SassHelperComponent;
	public sliderValue: any = 1;
	public materialParentTabSelected: any;
	public materialChildTabSelected: any = { textLabel: "Chart" };
	public split1Percent = 50;
	public split2Percent = 50;
	chart: any;
	Highcharts: typeof Highcharts = Highcharts;
	public sliderValuePlayback: number = 1;
	@ViewChild("tagDataGrid") tagDataGrid;

	public global: IGlobal = Global;

	@ViewChild("drawer") drawer;
	@ViewChild("drawer2") drawer2;
	isLoading: boolean;
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@Optional() @Input() inputAssetObject: any;
	defaultLeftWidth = 30;
	defaultRightWidth = 70;
	mapDiv: string;
	mapForBreadcrumbs: any;
	public dateTimeFormat = "MM/dd/yyyy HH:mm";
	public startDateTime: any;
	public endDateTime: any;
	public durationHours: number;
	public durationMinutes: number;
	public isTimeSelectionValid: boolean = true;
	navigationLeftOpen: boolean = false;
	navigationRightOpen: boolean = false;
	@ViewChildren("stepsOfStepper") stepsOfStepper;

	today = new Date();
	form: FormGroup;

	public selectedRowArray: number[] = [];

	@ViewChild("stepper") stepper: MatStepper;
	public hasMapBeenLoaded = false;
	public showMap: boolean = true;
	public showTable: boolean = true;
	public showMapLegend: boolean = false;
	public showTooltip: boolean = false;
	public showLegend: boolean = false;
	public showPlotLines: boolean = true;

	public showPlotBands: boolean = false;

	public showYAxisTitles: boolean = true;

	public selectedEventsTabIndex: number = 0;

	powerTagStdId: number = undefined;

	markerclusterer: any;

	exampleDataModel: any = [
		{
			id: 1,
			lat: 39.051470035940035,
			lng: -84.65544369091212,
			userName: "Dylan",
			speed: "5mph",
			distance: "30 meters",
		},
		{
			id: 2,
			lat: 39.05146488089981,
			lng: -84.6549716221255,
			userName: "Dylan",
			speed: "5mph",
			distance: "30 meters",
		},
		{
			id: 3,
			lat: 39.05098472407489,
			lng: -84.65497363378226,
			userName: "Dylan",
			speed: "5mph",
			distance: "30 meters",
		},
	];
	public alarmGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: [],
			},
			take: 10,
		},
		columnsConfig: [
			{
				field: "AlarmName",
				title: "Fault Name",
				filterable: true,
				hidden: false,
				_width: 130,
			},
			{
				field: "Severity",
				title: "Severity",
				filterable: true,
				hidden: false,
				_width: 50,
			},
			{
				field: "DurationInSeconds",
				title: "Duration(Secs)",
				filterable: true,
				hidden: false,
				_width: 50,
			},
			{
				field: "AlarmStartDate",
				title: "Fault Start Date",
				filterable: true,
				filter: "date",
				_width: 150,
			},
			{
				field: "AlarmEndDate",
				title: "Fault End Date",
				filterable: true,
				filter: "date",
				_width: 150,
			},
		],
	};
	public gridSettings: any = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			// {
			//     field: "Id",
			//     title: "Id",
			//     filterable: false,
			//     hidden: false,
			// },
			{
				field: "Id",
				title: "Id",
				filterable: true,
				_width: 150,
				hidden: true,
			},
			{
				field: "Speed",
				title: "Speed",
				filterable: true,
				hidden: false,
				_width: 150,
			},
			{
				field: "EngineSpeed",
				title: "Engine Speed",
				filterable: true,
				hidden: false,
				_width: 150,
			},
			{
				field: "FuelLevel",
				title: "Fuel Level",
				filterable: true,
				hidden: false,
				_width: 150,
			},
			{
				field: "PowerOnValue",
				title: "Power On",
				filterable: true,
				hidden: false,
				_width: 150,
			},
			// {
			//     field: "userName",
			//     title: "Name",
			//     filterable: true
			// },
			// {
			//     field: "distance",
			//     title: "Distance",
			//     filterable: true
			// },
			{
				field: "JavascriptDate",
				title: "Date",
				filterable: true,
				filter: "date",
				_width: 150,
			},
			{
				field: "highestAlert",
				title: "Most Severe Alert",
				filterable: true,
				_width: 200,
			},
		],
	};
	drivePathPolyline: any;
	newLatLngBounds: any;
	realData: any = [];
	maxDate: Date;
	operationalDetails: any;
	historicAlarms: any = [];
	historicAlerts: any = [];
	dataDisplay: boolean = false;
	signalRTagUpdateSubscription: any;
	jbtObservationList: string;
	assetId: any;
	tagObjectList: any;
	drivePathPolylineArray: any[];
	googlePolylines: any[];
	public geofencingStandardObservationIdsForAlerts: any = [
		56442, 56443, 56444, 56445, 56446, 56447, 56448, 56449, 56450, 56451,
		56452, 56453, 56454, 56455, 56456, 56457, 56458, 56459, 56460, 56461,
	];
	tilesHaveBeenLoadedFirstTime: boolean;
	legend: HTMLDivElement;
	timeZoneType: any;
	assetObject: any;
	siteObject: any;
	sidenavToggle: any;
	chartSeriesData: any = [];
	displayedColumns: string[] = ["FinalName", "FinalValue"];

	dataSource = [
		{ position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
		{ position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
		{ position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
		{ position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
		{ position: 5, name: "Boron", weight: 10.811, symbol: "B" },
		{ position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
		{ position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
		{ position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
		{ position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
		{ position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" },
	];
	weekOfPowerData: any;
	sliderDataForWeekSelection: any = [];
	currentDataIndexForPowerOnSlider: number = 0;
	testInterval: NodeJS.Timeout;
	colorChangedSubscription: any;
	listeningToSignalRMessages: boolean = false;
	defaultJbtStandardObservationList = [4504, 19130, 19141, 19229];
	jbtStandardObservationList: any[] = [4504, 19130, 19141, 19229];

	onlyDataColumnConfigs: any[] = [];
	existingTemplatesForUser: boolean = false;
	currentTemplate: any;
	templatesForUser: Array<any>;
	options: any;

	selectedTemplateId: any = 0;
	standardObservationsForSelectedTemplate: any = [];

	dashboard = [
		{ cols: 2, rows: 1, y: 0, x: 0 },
		{ cols: 2, rows: 2, y: 0, x: 2, hasContent: true },
		{ cols: 1, rows: 1, y: 0, x: 4 },
		{ cols: 1, rows: 1, y: 2, x: 5 },
		{ cols: 1, rows: 1, y: 1, x: 0 },
		{ cols: 1, rows: 1, y: 1, x: 0 },
		{
			cols: 2,
			rows: 2,
			y: 3,
			x: 5,
			minItemRows: 2,
			minItemCols: 2,
			label: "Min rows & cols = 2",
		},
		{
			cols: 2,
			rows: 2,
			y: 2,
			x: 0,
			maxItemRows: 2,
			maxItemCols: 2,
			label: "Max rows & cols = 2",
		},
		{
			cols: 2,
			rows: 1,
			y: 2,
			x: 2,
			dragEnabled: true,
			resizeEnabled: true,
			label: "Drag&Resize Enabled",
		},
		{
			cols: 1,
			rows: 1,
			y: 2,
			x: 4,
			dragEnabled: false,
			resizeEnabled: false,
			label: "Drag&Resize Disabled",
		},
		{ cols: 1, rows: 1, y: 2, x: 6 },
	];
	public shouldShowGauges = true;
	tagIdList: any = [];
	tagList: any = [];
	GraphTagsData: any;
	pointCount: any;
	allDataIsDigital: any;
	chartMetaData: {
		seriesData: { analog: any; digital: any; all: any };
		pointCount: any;
		yAxisInstances: any[];
	};
	chartWidgetSeries: any = [];
	previousMarkerIndex: any;
	previousMarker: any;
	show24HrTime: boolean = true;
	programticallyShowingTooltip: boolean = false;
	playbackHasBeenLoadedOnce: boolean = false;
	selectedTags = [4504, 19130, 19141, 19229];
	canvasTemplate: any;

	constructor(
		public dataService: DataService,
		private signalRCore: SignalRCoreService,
		private ref: ChangeDetectorRef,
		public gseService: GseService,
		private dashboardService: DashboardService,
		private utilityService: UtilityService,
		private zone: NgZone,
		private dialog: MatDialog,
		private mapsService: MapsService,
		private chartService: ChartService,
		private _decimalPlacesPipe: DecimalPlacesPipe
	) {}

	GetUnitsForWidget(widget) {
		if (widget.WidgetName == "Power On") {
			widget.minorUnit = 0.1;
			widget.majorUnit = 0.5;
		} else if (widget.WidgetName == "Engine Speed") {
			widget.minorUnit = 100;
			widget.majorUnit = 1000;
		} else if (widget.WidgetName == "Fuel Level") {
			widget.minorUnit = 10;
			widget.majorUnit = 50;
		} else if (widget.WidgetName == "Speedometer") {
			widget.minorUnit = 5;
			widget.majorUnit = 10;
		} else if (widget.WidgetName == "Catalyst Def Tank Level 1") {
			widget.minorUnit = 10;
			widget.majorUnit = 50;
		} else if (widget.WidgetName == "Coolant Temperature") {
			widget.minorUnit = 10;
			widget.majorUnit = 50;
		}
		// else {
		// 	widget.minorUnit = 5;
		// 	widget.majorUnit = 25;
		// }
	}

	formatLabelDate(value) {
		let ct = value.toLocaleString();
		let mt = moment(value).format("MM/DD/YYYY HH:mm:ss");
		if (this.show24HrTime) {
			return mt;
		} else {
			return ct;
		}
		// return date + ' : ' + cd;
	}

	
	CreateCanvasWidgetsForTemplate(template) {
		let canvasWidgets = this.dataService.cache.canvasWidgetTemplates.filter(
			(p: any) => {
				return p.CanvasTemplateId == parseInt(template.Id);
			}
		);
		if (canvasWidgets.length > 0) {
			for (const widget of canvasWidgets) {
				widget.Id = widget.Id;
				widget.WidgetId = widget.Id;
				widget.WidgetAssetId = this.widgetObject
					? this.widgetObject.AssetId
					: this.dataObject
					? this.dataObject.widgetObject.Id
					: this.inputAssetObject.Id;
				//widget.SiteList = cacheAssetObj.SiteId.toString();
				widget.cols = widget.Width;
				widget.rows = widget.Height;
				widget.x = widget.Col;
				widget.y = widget.Row;

				var canvasWidgetTemplateJBTStandardObservationsForUser =
					this.dataService.cache.canvasWidgetTemplateJBTStandardObservations.filter(
						(p: any) => {
							return p.CanvasWidgetTemplateId == widget.Id;
						}
					);

				if (
					canvasWidgetTemplateJBTStandardObservationsForUser.length >
					0
				) {
					widget.JBTStandardObservationId =
						canvasWidgetTemplateJBTStandardObservationsForUser[0].JBTStandardObservationId;
					widget.HexColorWhenActive =
						canvasWidgetTemplateJBTStandardObservationsForUser[0].HexColor;
					widget.MaxValue =
						canvasWidgetTemplateJBTStandardObservationsForUser[0].MaxValue;

					var JBTStandardObservationForUser =
						this.dataService.cache.jbtStandardObservations.filter(
							(so: any) => {
								return so.Id == widget.JBTStandardObservationId;
							}
						);
					widget.WidgetName = JBTStandardObservationForUser[0].Name;

					// get the minorUnit and majorUnit for widget type
					if (widget.AngularDirectiveName == "gaugeDataDisplay") {
						this.GetUnitsForWidget(widget);
					}

					// Get the ValueWhenActive from the Asset object cuz JBTStandardObservationForUser[0].ValueWhenActive is not right --kd
					let tag = this.dataService.cache.assetsObject[
						this.widgetObject
							? this.widgetObject.AssetId
							: this.dataObject
							? this.dataObject.widgetObject.Id
							: this.inputAssetObject.Id
					].Tags.filter((t: any) => {
						return (
							t.JBTStandardObservationId ==
							widget.JBTStandardObservationId
						);
					});

					widget.ValueWhenActive =
						tag.length > 0 ? tag[0].ValueWhenActive : 1;

					// get the gauge color ranges
					var canvasWidgetTemplateJBTStandardObservationGaugeColorRangesForUser =
						this.dataService.cache.canvasWidgetTemplateJBTStandardObservationGaugeColorRanges.filter(
							(r: any) => {
								return (
									r.CanvasWidgetTemplateJBTStandardObservationId ==
									canvasWidgetTemplateJBTStandardObservationsForUser[0]
										.Id
								);
							}
						);

					// get values from cache
					// 		array of CanvasWidgetTemplateJBTStandardObservationGaugeColorRange
					// 			from, to, hexcolor
					widget.GaugeColorRanges =
						canvasWidgetTemplateJBTStandardObservationGaugeColorRangesForUser;
				}

				//if CanvasWidgetType of type TagGraph,
				if (
					widget.CanvasWidgetTypeId == 28 ||
					widget.CanvasWidgetTypeId == 29
				) {
					if (widget.CanvasWidgetTypeId == 29)
						widget.IsSimpleTagGraph = true;
					let tagSeries =
						this.dataService.cache.canvasWidgetTemplateJBTStandardObservations.filter(
							(p: any) => {
								return (
									p.CanvasWidgetTemplateId ==
									parseInt(widget.Id)
								);
							}
						);
					if (tagSeries.length > 0)
						widget.canvasWidgetHasTagSeries = true;
					else widget.canvasWidgetHasTagSeries = false;
				}
			}
			template.assetTemplateHasNoWidgetsMessage = false;
			template.canvasWidgets = canvasWidgets;
		} else {
			template.assetTemplateHasNoWidgetsMessage = true;
			template.canvasWidgets = [];
		}

		// if (template.canvasWidgets?.length > 0) {
		// 	template.canvasWidgets.forEach((cw: any) => {
		// 		if (
		// 			cw.AngularDirectiveName == 'gseActiveAlarms' ||
		// 			cw.AngularDirectiveName == 'tagGraph' ||
		// 			cw.AngularDirectiveName == 'tagGraphSimple'
		// 		)
		// 			this.dashboardService.canvasWidgetsOnPunchOutScreenWithTimeZoneTimeScope.push(
		// 				cw
		// 			);
		// 	});
		// }
	}


	createCanvasGauges(form){
		var CanvasTemplateId  = this.dataService.cache.jbtStandardObservationIdTemplatesObject[parseInt(this.selectedTemplateId)].CanvasTemplateId;
		if(CanvasTemplateId == undefined || parseInt(CanvasTemplateId) == 0){
			this.getDataForDateRange(
				{
					form: form,
					getObservationsForDefaultList: true
				}
			)
		}
		else{
			this.canvasTemplate = this.dataService.cache.canvasTemplatesObject[parseInt(CanvasTemplateId)];
			this.CreateCanvasWidgetsForTemplate(this.canvasTemplate);
			this.getDataForDateRange(
				{
					form: form,
					getObservationsForDefaultList: true
				}
			)
		}
			
	}

	GetStandardObservationsForSelectedTemplate() {
		let stdIdList = this.GetStandardIdsForTemplate(this.currentTemplate);

		// build the display string to show the user the std obs associated with this template
		this.standardObservationsForSelectedTemplate = [];
		if (stdIdList.length > 0) {
			this.standardObservationsForSelectedTemplate =
				this.dataService.cache.jbtStandardObservations.filter(
					(o: any) => {
						return stdIdList.contains(o.Id);
					}
				);
		}
	}

	changeTemplate() {
		console.log("template changed", this.form.value.template);
		this.selectedTemplateId = parseInt(this.form.value.template);

		this.currentTemplate =
			this.dataService.cache.jbtStandardObservationIdTemplates.find(
				(template: any) => {
					return template.Id == this.selectedTemplateId;
				}
			);
		console.log(this.currentTemplate);
		if (this.currentTemplate) {
			this.selectedTags =
				this.dataService.cache.jbtStandardObservationIdWidgetTemplates
					.filter((t: any) => {
						return (
							t.JBTStandardObservationIdTemplateId ==
							this.currentTemplate.Id
						);
					})
					.map((t: any) => {
						return t.JBTStandardObservationId;
					});
			this.existingTemplatesForUser = true;
		}

		//this.GetStandardObservationsForSelectedTemplate();
		//this.CreateJBTStandardObservationIdWidgetsForTemplate(this.currentTemplate);
	}

	

	ngOnInit() {
		this.mapsService.loadGoogleMaps().then(() => {
			this.options = {
				gridType: GridType.Fixed, //will set the columns to fixedColWidth and rows height will fit the space available
				initCallback: BreadcrumbsComponent.gridInit, //callback to call after grid has initialized
				destroyCallback: BreadcrumbsComponent.gridDestroy, //callback to call after grid has destroyed
				gridSizeChangedCallback: this.gridSizeChanged.bind(this), //callback to call after grid has changed size cols/rows
				itemChangeCallback: this.itemChange.bind(this), //callback to call for each item when is changes x, y, rows, cols
				itemResizeCallback: this.itemResize.bind(this), //callback to call for each item when width/height changes
				itemInitCallback: this.itemInit.bind(this), //callback to call for each item when is initialized
				itemRemovedCallback: this.itemRemoved.bind(this), //callback to call for each item when is removed
				itemValidateCallback: BreadcrumbsComponent.itemValidate, //callback to call to validate item position/size. Return true if valid.
				compactType: CompactType.CompactUp,
				margin: 5,
				outerMargin: false,
				outerMarginTop: null,
				outerMarginRight: null,
				outerMarginBottom: null,
				outerMarginLeft: null,
				useTransformPositioning: false,
				mobileBreakpoint: 0,
				minCols: 1,
				maxCols: 60,
				height: Global.isMobile ? "2000px" : "2000px",
				width: Global.isMobile ? "2000px" : "2000px",
				minRows: 1,
				maxRows: 100,
				maxItemCols: 60,
				minItemCols: 1,
				maxItemRows: 100,
				minItemRows: 1,
				maxItemArea: 5000,
				minItemArea: 1,
				defaultItemCols: 1,
				defaultItemRows: 1,
				fixedColWidth: 60,
				fixedRowHeight: 60,
				setGridSize: false, //leave as true so that this displays properly on mac. Sets the grid height to the size of its child components.
				keepFixedHeightInMobile: false,
				keepFixedWidthInMobile: false,
				scrollSensitivity: 10,
				scrollSpeed: 20,
				enableEmptyCellClick: false,
				enableEmptyCellContextMenu: false,
				enableEmptyCellDrop: false,
				enableEmptyCellDrag: false,
				enableOccupiedCellDrop: false,
				emptyCellDragMaxCols: 50,
				emptyCellDragMaxRows: 50,
				ignoreMarginInRow: false,
				draggable: {
					enabled: true,
					ignoreContent: true,
					dragHandleClass: "drag-handler",
				},
				resizable: {
					enabled: false,
				},
				swap: false,
				pushItems: true,
				disablePushOnDrag: false,
				disablePushOnResize: false,
				pushDirections: {
					north: true,
					east: false,
					south: true,
					west: false,
				},
				pushResizeItems: false,
				displayGrid: DisplayGrid.Always,
				disableWindowResize: false,
				disableWarnings: false,
				scrollToNewItems: false,
			};

			let maxDate = new Date();
			// maxDate.setDate(maxDate.getDate() - 1)
			this.maxDate = maxDate;
			let startDate = new Date();
			startDate.setMinutes(startDate.getMinutes() - 10); // default is Last 10 minutes
			this.startDateTime = startDate;
			this.endDateTime = new Date();
			if (this.determineIfLive() === true) {
				this.listeningToSignalRMessages = true;
			} else {
				this.listeningToSignalRMessages = false;
			}
			console.log(this.dataObject);
			console.log(this.widgetObject);
			if (
				this.widgetObject?.VocationalSettingsJSON ||
				this.dataObject?.parentWidget?.VocationalSettingsJSON
			) {
				//if pierce, different tags
				this.jbtStandardObservationList = [
					19130, 63841, 77199, 77200, 19257, 59190, 63186, 70336,
				];
				this.defaultJbtStandardObservationList = [
					19130, 63841, 77199, 77200, 19257, 59190, 63186, 70336,
				];
			}

			if (
				this.dataObject?.parentWidget?.AngularDirectiveName ==
				"fireTruckOverviewAerial"
			) {
				//if fireTruckOverviewAerial, different tags
				this.defaultJbtStandardObservationList = [
					71744, 71741, 71748, 71747, 71746, 71750, 70020, 63825,
				];
			}
			if (
				this.dataObject?.parentWidget?.AngularDirectiveName ==
				"fireTruckOverviewDriveline"
			) {
				//if fireTruckOverviewDriveline, different tags
				this.defaultJbtStandardObservationList = [
					59190, 19257, 19270, 19180, 59313, 77198, 19130, 70022,
				];
			}

			this.selectedTags = this.defaultJbtStandardObservationList;

			this.exampleDataModel.forEach((obs, index) => {
				let dateTime = new Date();
				dateTime.setDate(dateTime.getDate() - index);
				obs.date = dateTime;
			});
			// this.realData = this.exampleDataModel;

			this.form = new FormGroup({
				startDateTime: new FormControl(this.startDateTime),
				endDateTime: new FormControl(this.endDateTime),
				timeBack: new FormControl(10),
				template: new FormControl(0),
			});

			this.templatesForUser =
				this.dataService.cache.jbtStandardObservationIdTemplates.filter(
					(p: any) => {
						return (
							//p.CanvasTemplateTypeId == 2 && // Get the canvas templates for breadcrumbs
							p.CreatorUserId ==
							Global.User.currentUser.Id.toString()
						);
					}
				);

			if (this.templatesForUser.length > 0) {
				this.existingTemplatesForUser = true;
			}

			this.isLoading = false;
			// let tempMapDiv = "breadCrumbsMap" + this.widgetObject.WidgetId.toString();

			if (this.widgetObject !== undefined) {
				this.mapDiv =
					"breadCrumbsMap" + this.widgetObject.WidgetId.toString();
				let statement =
					"API.GetWidgetTabSettings @WidgetId = " +
					this.widgetObject.WidgetId;
				this.dataService
					.SQLActionAsPromise(statement)
					.then((data: any) => {
						console.log("data", data);
						if (data.length > 0) {
							this.widgetObject.tabSettings = data;
							this.split1Percent =
								this.widgetObject.tabSettings[0].SplitPanel1Percent;
							this.split2Percent =
								this.widgetObject.tabSettings[0].SplitPanel2Percent;
						}
					});
			} else {
				this.mapDiv = "breadCrumbsMap";
			}

			if (!_.isNil(this.widgetObject)) {
				this.timeZoneType = this.dashboardService.determineTimeZoneType(
					this.widgetObject
				);
				this.siteObject = this.dataService.cache.assets.find(
					(asset) => {
						return asset.Id === parseInt(this.widgetObject.AssetId);
					}
				)?.Site;
			} else if (this.dataObject !== undefined) {
				this.timeZoneType = this.dashboardService.determineTimeZoneType(
					this.dataObject
				);
				this.siteObject = this.dataObject.widgetObject.Site;
			} else if (this.inputAssetObject !== undefined) {
				this.timeZoneType = this.dashboardService.determineTimeZoneType(
					this.inputAssetObject
				);
				this.siteObject = this.inputAssetObject.Site;
			}

			if (this.siteObject === undefined) {
				this.siteObject = {
					UTCTimeOffset: 0,
				};
			}
			if (this.dataObject !== undefined) {
				this.assetId = parseInt(this.dataObject.widgetObject.Id);
			} else if (this.widgetObject !== undefined) {
				this.assetId = parseInt(
					this.widgetObject.AssetId
						? this.widgetObject.AssetId
						: this.widgetObject.WidgetAssetId
				);
			} else if (this.inputAssetObject !== undefined) {
				this.assetId = parseInt(this.inputAssetObject.Id);
			}

			this.jbtObservationList = [54260, 54261].toString();
		});
	}

	ngAfterViewInit() {
		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				if (data === "light") {
					this.determineToDrawChart(true);
				} else {
					this.determineToDrawChart(true);
				}
			});

		if (this.dataObject?.breadcrumbTags?.length > 0) {
			this.shouldShowGauges = false;
			if (this.dataObject.timeBeforeToStart) {
				this.getDataForDateRange({
					getObservationsForDefaultList: true,
					timeBeforeToStart: this.dataObject.timeBeforeToStart,
				});
			}
		} else if (this.widgetObject?.breadcrumbTags?.length > 0) {
			this.shouldShowGauges = false;
			// this.getDataForDateRange({ getObservationsForDefaultList: true });
		} else if (this.inputAssetObject?.breadcrumbTags?.length > 0) {
			this.shouldShowGauges = false;
			// this.getDataForDateRange({ getObservationsForDefaultList: true });
		}

		let fleetId = undefined;
		if (this.dataObject != undefined) {
			var VocationalSettings = JSON.parse(
				this.dataObject.parentWidget.VocationalSettingsJSON
			);
			fleetId = VocationalSettings?.id;
		} else if (this.widgetObject != undefined) {
			fleetId = this.widgetObject.FleetId;
		}

		if (fleetId !== undefined) {
			this.powerTagStdId = 59219;
		} else {
			this.powerTagStdId = 4504;
		}

		this.dataService
			.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
				this.assetId.toString(),
				false,
				this.jbtObservationList
			)
			.subscribe((data: any) => {
				this.tagObjectList = data;
				console.log(data);
				let today = new Date().valueOf();
				let weekBack = new Date();
				let weekBackValue = weekBack.setDate(weekBack.getDate() - 7);
				let powerOnTag = this.dataService.cache.assetsObject[
					this.assetId
				].Tags.find(
					(t) => t.JBTStandardObservationId == this.powerTagStdId
				);
				if (powerOnTag !== undefined) {
					let statement =
						"API.GetObservationAggregatedHighChartValuesWithEpochTime " +
						"@tagId = " +
						powerOnTag.TagId +
						", @startDateTimeEpoch = '" +
						weekBackValue +
						"', @endDateTimeEpoch = '" +
						today +
						"'";

					this.dataService
						.SQLActionAsPromise(statement)
						.then((data: any) => {
							console.log(data);
							this.weekOfPowerData = data.reduce((o, cur) => {
								// Get the index of the key-value pair.
								var occurs = o.reduce((n, item, i) => {
									return item.Tag.Id === cur.TagId ? i : n;
								}, -1); // If the name is found,
								if (occurs >= 0) {
									// append the current value to its list of values.
									o[occurs].TagValues = o[
										occurs
									].TagValues.concat(cur.TagValues); // Otherwise,
								} else {
									// add the current item to o (but make sure the value is an array).
									var obj = {
										Tag: {
											Name: cur.tagName,
											Id: cur.TagId,
											JBTStandardObservationId:
												cur.JBTStandardObservationId,
											Asset: {
												Name: cur.AssetName,
												Site: {
													Name: cur.SiteName,
												},
											},
										},
										JBTStandardObservationName:
											cur.JBTStandardObservationName,
										GateName: cur.GateName,
										TagValues: cur.TagValues,
									};
									o = o.concat([obj]);
								}
								return o;
							}, []);
							this.weekOfPowerData = _.orderBy(
								this.weekOfPowerData,
								["OrdinalFromDb"],
								["desc"]
							);
							_.forEach(this.weekOfPowerData, (graphTag) => {
								graphTag.AggregateObservations =
									graphTag.TagValues.split("|")
										.filter((v) => {
											return v.length > 5;
										})
										.map((valuePair) => {
											var numericValuePair =
												valuePair.split(",");
											return [
												+numericValuePair[0],
												+numericValuePair[1] || 0,
											];
										});
							});
							console.log(this.weekOfPowerData);
							let sliderDataForWeekSelection = [];
							if (this.weekOfPowerData.length > 0) {
								sliderDataForWeekSelection =
									this.weekOfPowerData[0].AggregateObservations.filter(
										(obs) => {
											return obs[1] > 0;
										}
									);
							}

							this.sliderDataForWeekSelection =
								sliderDataForWeekSelection.filter(
									(innerArr, index, self) => {
										return (
											index ===
											self.findIndex((t) => {
												return t.every(
													(el, i) =>
														el === innerArr[i]
												);
											})
										);
									}
								);
						});
				}

				let lat = this.dataService.cache.assetsObject[
					this.assetId
				].Tags.find((t) => t.JBTStandardObservationId == 54260);
				console.log(lat);
			});

		//logic for going to retrieve data from database using parameters.
	}

	determineAlarmDate(time, offset) {
		if (this.timeZoneType === "User Time") {
			return time;
		} else if (this.timeZoneType === "Site Time") {
			return this.utilityService.convertFromUtcToLocalToSite(
				time.valueOf(),
				offset
			);
		} else if (this.timeZoneType === "UTC Time") {
			return this.utilityService.convertFromUtcToLocalToSite(
				time.valueOf(),
				0
			);
		}
	}

	initializeDataAndMap(configuration: {
		listenForSignalR?: boolean;
		componentInitialization?: boolean;
		idListFromTemplate?: any;
		getObservationsForDefaultList?: boolean;
	}) {
		if (this.determineIfLive() === true) {
			this.listeningToSignalRMessages = true;
		} else {
			this.listeningToSignalRMessages = false;
		}
		this.sliderValuePlayback = 1;
		this.tilesHaveBeenLoadedFirstTime = false;

		console.log(this.widgetObject);
		let startDateFormatted = moment(this.startDateTime).format(
			"YYYY/MM/DD HH:mm:ss"
		);
		let endDateFormatted = moment(this.endDateTime).format(
			"YYYY/MM/DD HH:mm:ss"
		);

		this.realData = [];
		let startDateAsUTC = this.startDateTime.getTime();
		let endDateAsUTC = this.endDateTime.getTime();
		this.jbtStandardObservationList = [];

		if (this.timeZoneType === "Site Time") {
			startDateAsUTC = this.utilityService
				.convertMillisecondsToDateWithTimezoneOffset(
					startDateAsUTC,
					this.siteObject.UTCTimeOffset
				)
				.valueOf();
			endDateAsUTC = this.utilityService
				.convertMillisecondsToDateWithTimezoneOffset(
					endDateAsUTC,
					this.siteObject.UTCTimeOffset
				)
				.valueOf();
		} else if (this.timeZoneType === "UTC Time") {
			startDateAsUTC = this.utilityService
				.convertMillisecondsToDateWithTimezoneOffset(startDateAsUTC, 0)
				.valueOf();
			endDateAsUTC = this.utilityService
				.convertMillisecondsToDateWithTimezoneOffset(endDateAsUTC, 0)
				.valueOf();
		}

		if (
			configuration.idListFromTemplate != null &&
			configuration.getObservationsForDefaultList === false
		) {
			this.jbtStandardObservationList = configuration.idListFromTemplate;
		} else {
			this.selectedTags.forEach((id) => {
				this.jbtStandardObservationList.push(id);
			});
			if (this.dataObject?.breadcrumbTags?.length > 0) {
				this.jbtStandardObservationList.push(
					this.dataObject.breadcrumbTags[0].JBTStandardObservationId
				);
				this.shouldShowGauges = false;
			} else if (this.widgetObject?.breadcrumbTags?.length > 0) {
				this.jbtStandardObservationList.push(
					this.widgetObject.breadcrumbTags[0].JBTStandardObservationId
				);
				this.shouldShowGauges = false;
			} else if (this.inputAssetObject?.breadcrumbTags?.length > 0) {
				this.jbtStandardObservationList.push(
					this.inputAssetObject.breadcrumbTags[0]
						.JBTStandardObservationId
				);
				this.shouldShowGauges = false;
			}
		}

		this.jbtStandardObservationList =
			this.jbtStandardObservationList.filter((id) => {
				if (
					this.dataService.cache.assetsObject[this.assetId].Tags.find(
						(t) => t.JBTStandardObservationId === id
					) !== undefined
				) {
					return true;
				} else {
					return false;
				}
			});
		if (this.jbtStandardObservationList.length === 0) {
			this.utilityService.showToastMessageShared({
				type: "error",
				message:
					"This asset does not have any of those tags you are trying to retrieve. Please select a different set of tags.",
				title: "Error",
			});
			this.isLoading = false;

			return;
		}
		if (!this.jbtStandardObservationList.includes(54260)) {
			this.jbtStandardObservationList.push(54260);
		}
		if (!this.jbtStandardObservationList.includes(54261)) {
			this.jbtStandardObservationList.push(54261);
		}

		this.tagList = this.dataService.cache.assetsObject[
			this.assetId
		].Tags.filter((tag) => {
			return this.jbtStandardObservationList.includes(
				tag.JBTStandardObservationId
			);
		});

		this.tagIdList = this.tagList.map((tag) => {
			return tag.Id;
		});

		let tagIdListAsString = this.tagIdList.join(",");

		let statement =
			"API.GetObservationAggregatedHighChartValues_Playback @TagIdsAsString = '" +
			tagIdListAsString +
			"',  @startDateTimeEpoch = '" +
			startDateAsUTC +
			"', @endDateTimeEpoch = '" +
			endDateAsUTC +
			"'";

		console.log(statement);
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			console.log(this.jbtStandardObservationList);
			console.log(data);

			// let separateCollections = [];
			// let data = dataRaw.filter(
			// 	(d) => d.Latitude !== 0 && d.Longitude !== 0
			// );

			// let obsIdsFromDataset = _.uniq(
			// 	data.map((d) => {
			// 		return d.JBTStandardObservationId;
			// 	})
			// );
			// if(data.length === 0){
			// 	this.utilityService.showToastMessageShared({
			// 		type: "info",
			// 		message: "There was no movement during the selected time period for this vehicle, so no breadcrumbs will be plotted.",
			// 		title: "No movement during time period for vehicle. ",
			// 	});
			// }
			// else if (
			// 	obsIdsFromDataset.length !==
			// 	this.jbtStandardObservationList.length
			// ) {
			// 	let missingIds = this.jbtStandardObservationList.filter(
			// 		(id) => {
			// 			return !obsIdsFromDataset.includes(id);
			// 		}
			// 	);
			// 	let listOfMissingJbtStandardObjects =
			// 		this.dataService.cache.jbtStandardObservations.filter(
			// 			(jbt) => {
			// 				return missingIds.includes(jbt.Id);
			// 			}
			// 		);
			// 	this.utilityService.showToastMessageShared({
			// 		type: "info",
			// 		message: listOfMissingJbtStandardObjects
			// 			.map((jbt) => jbt.Name)
			// 			.join(", "),
			// 		title: "The following JBT Standard Observations were not found in the dataset: ",
			// 	});

			// 	this.jbtStandardObservationList = obsIdsFromDataset;
			// 	//didnt have tags for some of the ids that we passed. Need to account for this and notify user.
			// }
			// this.jbtStandardObservationList.forEach((jbtOb) => {
			// 	let collection = data.filter((record) => {
			// 		return record.JBTStandardObservationId === jbtOb;
			// 	});
			// 	separateCollections.push(collection);
			// });

			// ObsValue
			this.GraphTagsData = data.reduce((o, cur) => {
				// Get the index of the key-value pair.
				var occurs = o.reduce((n, item, i) => {
					return item.Tag.Id === cur.TagId ? i : n;
				}, -1); // If the name is found,
				if (occurs >= 0) {
					// append the current value to its list of values.
					o[occurs].TagValues = o[occurs].TagValues.concat(
						cur.TagValues
					); // Otherwise,
				} else {
					// add the current item to o (but make sure the value is an array).
					var obj = {
						Tag: {
							Name: cur.tagName,
							Id: cur.TagId,
							JBTStandardObservationId:
								cur.JBTStandardObservationId,
							Asset: {
								Name: cur.AssetName,
								Site: {
									Name: cur.SiteName,
								},
							},
						},
						JBTStandardObservationName:
							cur.JBTStandardObservationName,
						GateName: cur.GateName,
						TagValues: cur.TagValues,
					};
					o = o.concat([obj]);
				}
				return o;
			}, []);

			_.forEach(this.GraphTagsData, (graphTag) => {
				graphTag.AggregateObservations = graphTag.TagValues.split("|")
					.filter((v) => {
						return v.length > 5;
					})
					.map((valuePair) => {
						var numericValuePair = valuePair.split(",");
						return [
							+numericValuePair[0],
							+numericValuePair[1] || 0,
						];
					});
			});

			let seriesData = this.GraphTagsData.filter((gt) => {
				return gt.MarginObservations || gt.AggregateObservations;
			}).map((gt) => {
				var lastMarginDataPair;
				if (gt.AggregateObservations) {
					lastMarginDataPair = _.last(gt.AggregateObservations);
				}

				var trimmedMarginDataPairs;
				if (lastMarginDataPair) {
					trimmedMarginDataPairs = gt.MarginObservations
						? gt.MarginObservations.skipWhile((m) => {
								return m[0] <= lastMarginDataPair[0];
						  })
						: [];
				} else {
					trimmedMarginDataPairs = gt.MarginObservations;
				}

				var dataPairs;
				if (gt.AggregateObservations) {
					dataPairs = gt.AggregateObservations.concat(
						trimmedMarginDataPairs
					);
				} else {
					dataPairs = trimmedMarginDataPairs;
				}

				gt.isDigital = this.chartService.IsDataDigital(dataPairs);

				let nameString = "";
				if (gt.Tag.Asset.Site.Name === null) {
					if (
						this.dataService.cache.jbtStandardObservationsObject[
							gt.Tag.JBTStandardObservationId
						] !== undefined
					) {
						nameString =
							gt.Tag.Asset.Site.Name +
							" " +
							gt.GateName +
							" " +
							gt.Tag.Asset.Name +
							" " +
							this.dataService.cache
								.jbtStandardObservationsObject[
								gt.Tag.JBTStandardObservationId
							].Name;
					} else {
						nameString = gt.Tag.Name;
					}
				} //Display Gate Name only if Gate System is not GSE
				else if (gt.GateName != null && !gt.GateName.includes("GSE")) {
					if (
						this.dataService.cache.jbtStandardObservationsObject[
							gt.Tag.JBTStandardObservationId
						] !== undefined
					) {
						nameString =
							gt.Tag.Asset.Site.Name +
							" " +
							gt.GateName +
							" " +
							gt.Tag.Asset.Name +
							" " +
							this.dataService.cache
								.jbtStandardObservationsObject[
								gt.Tag.JBTStandardObservationId
							].Name;
					} else {
						nameString = gt.Tag.Name;
					}
				} else {
					if (
						this.dataService.cache.jbtStandardObservationsObject[
							gt.Tag.JBTStandardObservationId
						] !== undefined
					) {
						nameString =
							gt.Tag.Asset.Site.Name +
							" " +
							gt.GateName +
							" " +
							gt.Tag.Asset.Name +
							" " +
							this.dataService.cache
								.jbtStandardObservationsObject[
								gt.Tag.JBTStandardObservationId
							].Name;
					} else {
						nameString = gt.Tag.Name;
					}
				}
				//				SiteLocalJavascriptDate: this.utilityService.convertFromUtcToLocalToSite(+tarray[4], this.cache.sitesObject[+tarray[2]].UTCTimeOffset), // Get UTC TimeZone offset then call utility service to convert the utc milliseconds to site date
				let site = gt.Tag.Asset.Site.Name;
				let siteObject: any;
				if (site !== undefined) {
					siteObject = this.dataService.cache.sites.find((ste) => {
						return ste.Name === site;
					});
				}
				var seriesCounter = 0;

				let dataArray = (gt.AggregateObservations || [])
					.concat(gt.MarginObservations)
					// .orderBy(function(vp) {
					//   return vp[0];
					// })
					.filter((o) => {
						return o;
					})
					.map((point) => {
						this.pointCount++;
						return [
							point[0],
							gt.isDigital
								? point && (point[1] || 0)
								: this._decimalPlacesPipe.transform(
										point[1],
										1,
										gt.Tag.JBTStandardObservationId
								  ),
						];
					});
				var seriesObject: any = {
					seriesNumber: seriesCounter++,
					connectNulls: true,
					tagId: gt.Tag.Id,
					Asset: gt.Tag.Asset.Name,
					longTagName: gt.Tag.Name,
					isDigital: gt.isDigital,
					//groupPixelWidth: 5,
					name: nameString,
					type: gt.SeriesType,
					JBTStandardObservationId:
						this.dataService.cache.jbtStandardObservationsObject[
							gt.Tag.JBTStandardObservationId
						] !== undefined
							? this.dataService.cache
									.jbtStandardObservationsObject[
									gt.Tag.JBTStandardObservationId
							  ].Id
							: "",
					jbtStandardObservationName:
						this.dataService.cache.jbtStandardObservationsObject[
							gt.Tag.JBTStandardObservationId
						] !== undefined
							? this.dataService.cache
									.jbtStandardObservationsObject[
									gt.Tag.JBTStandardObservationId
							  ].Name
							: "",
					data: dataArray,
					color: !_.isNil(gt.HexColor)
						? gt.HexColor
						: Highcharts.getOptions().colors[seriesCounter],
					//siteTimeData:
				};
				if (!_.isNil(siteObject)) {
					seriesObject.siteObject = siteObject;
				}
				let colors = Highcharts.getOptions().colors;

				seriesObject["step"] = "left";
				return seriesObject;
			});
			this.allDataIsDigital = this.GraphTagsData.every((gt) => {
				return gt.isDigital;
			});

			var pointCount = 0;

			this.chartMetaData = {
				seriesData: {
					analog: seriesData.filter((d) => {
						return !d.isDigital;
					}),
					digital: seriesData.filter((d) => {
						return d.isDigital;
					}),
					all: seriesData,
				},
				pointCount: this.pointCount,
				yAxisInstances: [],
			};

			var genericHeightPercentage = 100 / seriesData.length;
			var rollingTopPercentage = 0;

			//Place the digital axis onto the chart plot first if there are digital entries.
			var yAxisNumber = 0;

			//++Digital yAxis
			this.chartMetaData.seriesData.digital.forEach((sd) => {
				let titleVarR = sd.name.split("(");
				let titleVar = _.first(titleVarR);
				this.chartMetaData.yAxisInstances.push({
					opposite: true,
					iOPSType: "digital",
					type: "linear",
					offset: 30,
					tagIds: [sd.tagId],
					min: -0.2,
					max: 1.2,
					minRange: 1.4,
					tickInterval: 0.5,
					top: rollingTopPercentage + "%",
					height: genericHeightPercentage * 0.9 + "%",
					//visible: yAxisNumber == 0,
					labels: {
						// enabled: false,
						formatter: function () {
							return this.value;
						},
						style: {
							color: sd.color,
						},
					},
					resize: {
						enabled: false,
					},
					title: {
						text:
							'<div style="margin-left: 10px">' +
							sd.jbtStandardObservationName +
							"</div>",
						rotation: Global.isMobile ? 90 : 0,
						textAlign: "right",
						align: "middle",
						useHTML: true,

						// margin: Global.isMobile ? 0 : 40,
						// offset: Global.isMobile ? undefined : 80,
						// style: {
						// 	color: "#212427",
						// 	// fontSize: "12px",
						// },
					},
					gridLineWidth: 0,
					minorGridLineWidth: 0,
					plotLines: [
						{
							value: 0,
							width: 2,
						},
					],
					gridLineColor: "#424242",
					ridLineWidth: 1,
					minorGridLineColor: "#424242",
					inoGridLineWidth: 0.5,
					tickColor: "#424242",
					minorTickColor: "#424242",
					lineColor: "#424242",
				});
				rollingTopPercentage += genericHeightPercentage;

				sd.yAxis = yAxisNumber++;
			});
			this.chartMetaData.seriesData.analog.forEach((sd) => {
				let titleVarR = sd.name.split("(");
				let titleVar = _.first(titleVarR);
				this.chartMetaData.yAxisInstances.push({
					opposite: true,
					visible: true,
					iOPSType: "analog - " + sd.name,
					type: "linear",
					offset: 30,
					tagIds: [sd.tagId],
					top: rollingTopPercentage + "%",
					height: genericHeightPercentage * 0.9 + "%",
					labels: {
						// enabled: false,
						formatter: function () {
							return this.value;
						},
						style: {
							color: sd.color,
						},
					},
					resize: {
						enabled: false,
					},
					title: {
						text:
							'<div style="margin-left: 10px">' +
							sd.jbtStandardObservationName +
							"</div>",
						rotation: Global.isMobile ? 90 : 0,
						textAlign: "right",
						align: "middle",
						useHTML: true,
						// margin: Global.isMobile ? 0 : 40,
						// offset: Global.isMobile ? undefined : 80,
						// style: {
						// 	color: "#212427",
						// 	// fontSize: "12px",
						// },
					},
					plotLines: [
						{
							value: 0,
							width: 2,
						},
					],
				});
				rollingTopPercentage += genericHeightPercentage;

				sd.yAxis = yAxisNumber++;
			});

			//debugger;
			var interpolatedData = this.chartService.GetInterpolatedData(
				this.chartMetaData.seriesData.all
			);

			var interpolatedDataFiltered = interpolatedData.filter((d) => {
				const hasNullValue = Object.values(d).some(
					(value) => value === null
				);
				return !hasNullValue;
			});

			this.chartMetaData.seriesData.all.forEach((series) => {
				//mstime is offset based off the timezonetype. If user time, then leave as is. If widget is site time and I select 7:30 am on the widget for a stie that is east coast and I am central, then the call would convert 7:30 to 8:30 am in milliseconds since 1970 and return 8:30 as milliseconds since 1970. What I need to do is convert the 8:30 back to 7:30 am for the interpolated datain milliseconds since 1970. Then the conversions work correctly.
				if (this.timeZoneType === "Site Time") {
					let timeZoneOffsetDifferenceBetweenSiteAndUser =
						Math.abs(
							this.utilityService.GetUserTimeZoneOffsetHours()
						) *
							-1 -
						series.siteObject.UTCTimeOffset;
					series.data = interpolatedData.map((item) => {
						return [
							item.dateMS +
								timeZoneOffsetDifferenceBetweenSiteAndUser *
									3600000,
							item[series?.tagId]?.replace
								? parseFloat(
										item[series?.tagId]?.replace(/,/g, "")
								  )
								: item[series?.tagId],
						];
					});
				} else if (this.timeZoneType === "UTC Time") {
					series.data = interpolatedData.map((item) => {
						return [
							item.dateMS -
								this.utilityService.GetUserTimeZoneOffsetHours() *
									3600000,
							item[series?.tagId]?.replace
								? parseFloat(
										item[series?.tagId]?.replace(/,/g, "")
								  )
								: item[series?.tagId],
						];
					});
				} else {
					series.data = interpolatedData.map((item) => {
						return [
							item.dateMS,
							item[series?.tagId]?.replace
								? parseFloat(
										item[series?.tagId]?.replace(/,/g, "")
								  )
								: item[series?.tagId],
						];
					});
				}

				if (series.siteObject !== undefined) {
					let siteDateDataArray = series.data.map((point) => {
						return [
							this.utilityService.convertFromUtcToLocalToSite(
								point[0],
								series.siteObject.UTCTimeOffset,
								true
							),
							point[1],
						];
					});
					let utcDateDataArray = series.data.map((point) => {
						return [
							this.utilityService.convertFromUtcToLocalToSite(
								point[0],
								0,
								true
							),
							point[1],
						];
					});
					series.siteDateDataArray = siteDateDataArray;
					series.utcDateDataArray = utcDateDataArray;
				}
			});

			this.chartMetaData.seriesData.all.forEach((sd) => {
				pointCount += sd.data.length;
			});
			this.pointCount = pointCount;
			this.chartWidgetSeries =
				this.chartMetaData.seriesData.analog.concat(
					this.chartMetaData.seriesData.digital
				);
			let newRealData = [];
			// if (this.timeZoneType === "Site Time") {
			// 	this.chartWidgetSeries.forEach((se) => {
			// 		// let arrayOfArraysForDebugging =
			// 		se.data = se.siteDateDataArray;
			// 	});
			// } else if (this.timeZoneType === "UTC Time") {
			// 	this.chartWidgetSeries.forEach((se) => {
			// 		// let arrayOfArraysForDebugging =
			// 		se.data = se.utcDateDataArray;
			// 	});
			// }
			this.chartWidgetSeries.forEach((cs, index) => {
				let yAxisIndexFound =
					this.chartMetaData.yAxisInstances.findIndex(
						(yAxisIndex) => {
							return yAxisIndex.tagIds.includes(cs.tagId);
						}
					);
				if (yAxisIndexFound !== -1) {
					cs.yAxis = yAxisIndexFound;
				}
				(cs.color = Highcharts.getOptions().colors[index + 1]),
					(cs.yAxis = index);

				//creating table data
				cs.name = cs.jbtStandardObservationName;
				cs.data.forEach((dataPoint, index) => {
					let tagRecordInstance = {
						asset: cs.Asset,
						jbtStandardObservationName:
							cs.jbtStandardObservationName,
						site: cs.siteObject?.Name,
						JavascriptDate: new Date(dataPoint[0]),
						SiteLocalJavascriptDate: new Date(
							cs.siteDateDataArray[index][0]
						),
						UTCJavascriptDate: new Date(
							cs.utcDateDataArray[index][0]
						),
						Name: cs.longTagName,
						Value: dataPoint[1],
					};
					// this.realData.push(tagRecordInstance);
				});

				if (index === 0) {
					let realDataObject: any = {};
					cs.data.forEach((dataPoint, index) => {
						let fieldName =
							cs.jbtStandardObservationName.replaceAll(" ", "_");
						newRealData.push({
							[fieldName]: dataPoint[1],
							Date: new Date(dataPoint[0]),
							JavascriptDate: new Date(dataPoint[0]),
							SiteLocalJavascriptDate: new Date(
								cs.siteDateDataArray[index][0]
							),
							UTCJavascriptDate: new Date(
								cs.utcDateDataArray[index][0]
							),
						});
					});
				} else {
					cs.data.forEach((dataPoint, index) => {
						let fieldName =
							cs.jbtStandardObservationName.replaceAll(" ", "_");
						let realDataObject = newRealData[index];
						realDataObject[fieldName] = dataPoint[1];
					});
				}
			});
			console.log(this.realData);
			var timedifference = newRealData[0].Date.getTimezoneOffset();

			Highcharts.setOptions({
				time: {
					timezoneOffset: timedifference,
				},
				colors: ["#EC2515", "#0083F5", "#00D6B4", "#D451D9", "#FF8379"],
			});

			console.log(newRealData);
			this.chartWidgetSeries.forEach((series) => {
				series.data = series.data.filter((d) => {
					if (d[0] >= startDateAsUTC || d[0] <= endDateAsUTC) {
						return d;
					}
				});
			});

			newRealData = newRealData.filter((d) => {
				if (
					d.Date.getTime() >= startDateAsUTC ||
					d.Date.getTime() <= endDateAsUTC
				) {
					return d;
				}
			});

			if (data.length > 0) {
				this.gridSettings.columnsConfig = [];
				// this.gridSettings.columnsConfig.push({
				// 	field: "Id",
				// 	title: "Id",
				// 	filterable: true,
				// 	_width: 150,
				// 	hidden: true,
				// });

				this.gridSettings.columnsConfig.push({
					field: "Date",
					title: "Date",
					filterable: true,
					filter: "date",
					_width: 150,
				});
				this.chartWidgetSeries.forEach((series) => {
					let fieldName =
						series.jbtStandardObservationName.replaceAll(" ", "_");
					this.gridSettings.columnsConfig.push({
						field: fieldName,
						title: series.jbtStandardObservationName,
						filterable: true,
						_width: 150,
					});
				});
				if (this.canvasTemplate?.canvasWidgets) {
					this.canvasTemplate.canvasWidgets.forEach(
						(widget) => {
							let tagInfoConvertedToColumnConfig = {
								// field: widget.WidgetName.replace(" ", "_"),
								// title: widget.WidgetName,
								filterable: true,
								_width: 150,
								hidden: false,
								max: 100,
								minorUnit: 10,
								majorUnit: 25,
								rows: 4,
								cols: 4,
							};
							this.onlyDataColumnConfigs[
								widget.JBTStandardObservationId
							] = tagInfoConvertedToColumnConfig;
						}
					);
					this.shouldShowGauges = true;
				} else this.shouldShowGauges = false;
			}

			this.realData = newRealData;

			// end of todo
			if (this.realData.length === 0) {
				this.isLoading = false;
				setTimeout(() => {
					if (this.tagDataGrid) {
						this.tagDataGrid.gridDataSubject.next(this.realData);
					}
				}, 1000);

				return;
			} else {
				this.dataDisplay = false;
				let statement1 =
					"API.OperationalDetailsForAsset " +
					"@assetId = " +
					this.assetId +
					", @startDateTimeMS = " +
					startDateAsUTC +
					", @endDateTimeMS = " +
					endDateAsUTC;

				this.dataService
					.SQLActionAsPromise(statement1)
					.then((dataRaw: any) => {
						this.operationalDetails = [
							{ type: "realTime", records: [] },
							{ type: "aggregate", records: [] },
						];
						let idleHrs = [54264];
						let totalIdleHrs = [19133];
						let driveTime = [19127];
						let totalDriveTime = [19131];
						let totalOperateHrs = [19132];
						let engineOnHrs = [19125];
						let totalfuelUsed = [19161];

						let idleHrsInfo = dataRaw.find((t) =>
							idleHrs.includes(
								parseInt(t.JBTStandardObservationId)
							)
						);

						if (idleHrsInfo === undefined) {
							idleHrsInfo = {
								StandardName: "IdleHours",
								FinalValue: "-",
							};
						}
						idleHrsInfo.FinalName = "Idle Hours";
						idleHrsInfo.Id = 3;
						// this.operationalDetails.idleHrsInfo = this.evaluateTagValueForParseFloat(idleHrsInfo);
						this.operationalDetails[0].records.push(idleHrsInfo);
						let totalIdleHrsInfo = dataRaw.find((t) =>
							totalIdleHrs.includes(
								parseInt(t.JBTStandardObservationId)
							)
						);

						if (totalIdleHrsInfo === undefined) {
							totalIdleHrsInfo = {
								StandardName: "TotalIdleHours",
								FinalValue: "-",
							};
						}
						totalIdleHrsInfo.FinalName = "Total Idle Hours";
						totalIdleHrsInfo.Id = 4;
						// this.operationalDetails.totalIdleHrsInfo = this.evaluateTagValueForParseFloat(totalIdleHrsInfo);
						this.operationalDetails[1].records.push(
							totalIdleHrsInfo
						);
						let driveTimeInfo = dataRaw.find((t) =>
							driveTime.includes(
								parseInt(t.JBTStandardObservationId)
							)
						);

						if (driveTimeInfo === undefined) {
							driveTimeInfo = {
								StandardName: "DriveTime",
								FinalValue: "-",
							};
						}
						driveTimeInfo.FinalName = "Hours Driven";
						driveTimeInfo.Id = 1;
						// this.operationalDetails.driveTimeInfo = this.evaluateTagValueForParseFloat(driveTimeInfo);
						this.operationalDetails[0].records.push(driveTimeInfo);
						let totalDriveTimeInfo = dataRaw.find((t) =>
							totalDriveTime.includes(
								parseInt(t.JBTStandardObservationId)
							)
						);

						if (totalDriveTimeInfo === undefined) {
							totalDriveTimeInfo = {
								StandardName: "TotalDriveTime",
								FinalValue: "-",
							};
						}
						totalDriveTimeInfo.FinalName = "Total Hours Driven";
						totalDriveTimeInfo.Id = 2;
						// this.operationalDetails.totalDriveTimeInfo = this.evaluateTagValueForParseFloat(totalDriveTimeInfo);
						this.operationalDetails[1].records.push(
							totalDriveTimeInfo
						);
						let totalOperateHrsInfo = dataRaw.find((t) =>
							totalOperateHrs.includes(
								parseInt(t.JBTStandardObservationId)
							)
						);

						if (totalOperateHrsInfo === undefined) {
							totalOperateHrsInfo = {
								StandardName: "TotalOperateHrs",
								FinalValue: "-",
							};
						}
						totalOperateHrsInfo.FinalName = "Total Hours Operated";
						totalOperateHrsInfo.Id = 8;
						// this.operationalDetails.totalOperateHrsInfo = this.evaluateTagValueForParseFloat(totalOperateHrsInfo);
						this.operationalDetails[1].records.push(
							totalOperateHrsInfo
						);
						let engineOnHrsInfo = dataRaw.find((t) =>
							engineOnHrs.includes(
								parseInt(t.JBTStandardObservationId)
							)
						);

						if (engineOnHrsInfo === undefined) {
							engineOnHrsInfo = {
								StandardName: "EngineOnHours",
								FinalValue: "-",
							};
						}
						engineOnHrsInfo.FinalName = "Engine On Hours";
						engineOnHrsInfo.Id = 9;
						// this.operationalDetails.engineOnHrsInfo = this.evaluateTagValueForParseFloat(engineOnHrsInfo);
						this.operationalDetails[0].records.push(
							engineOnHrsInfo
						);
						let totalfuelUsedInfo = dataRaw.find((t) =>
							totalfuelUsed.includes(
								parseInt(t.JBTStandardObservationId)
							)
						);

						if (totalfuelUsedInfo === undefined) {
							totalfuelUsedInfo = {
								StandardName: "TotalFuelUsed",
								FinalValue: "-",
							};
						}
						totalfuelUsedInfo.FinalName = "Total Fuel Used (Ltrs)";
						totalfuelUsedInfo.Id = 6;
						// this.operationalDetails.totalfuelUsedInfo = this.evaluateTagValueForParseFloat(totalfuelUsedInfo);
						this.operationalDetails[1].records.push(
							totalfuelUsedInfo
						);

						let statement2 =
							"API.OperationalDetailsForAsset_MinMax " +
							"@assetId = " +
							this.assetId +
							", @startDateTimeMS = " +
							startDateAsUTC +
							", @endDateTimeMS = " +
							endDateAsUTC;

						this.dataService
							.SQLActionAsPromise(statement2)
							.then((dataRaw1: any) => {
								let fuelUsed = [19161];
								let operateTimeHrs = [19132];
								// dataRaw1.forEach((record) => {
								// 	this.operationalDetails.push(record);
								// }
								// );
								let fuelUsedInfo = dataRaw1.find((t) =>
									fuelUsed.includes(
										parseInt(t.JBTStandardObservationId)
									)
								);
								fuelUsedInfo = _.isNil(fuelUsedInfo)
									? {
											StandardName: "FuelUsed",
											FinalValue: "-",
									  }
									: {
											StandardName: "FuelUsed",
											FinalValue: parseFloat(
												fuelUsedInfo.FinalValue
											).toFixed(2),
									  };
								fuelUsedInfo.FinalName = "Fuel Used (Ltrs)";
								fuelUsedInfo.Id = 5;

								this.operationalDetails[0].records.push(
									fuelUsedInfo
								);
								let operateTimeHrsInfo = dataRaw1.find((t) =>
									operateTimeHrs.includes(
										parseInt(t.JBTStandardObservationId)
									)
								);

								operateTimeHrsInfo = _.isNil(operateTimeHrsInfo)
									? {
											StandardName: "OperateTimeHrs",
											FinalValue: "-",
									  }
									: {
											StandardName: "OperateTimeHrs",
											FinalValue: parseFloat(
												operateTimeHrsInfo.FinalValue
											).toFixed(2),
									  };
								operateTimeHrsInfo.FinalName = "Hours Operated";
								operateTimeHrsInfo.Id = 7;
								this.operationalDetails[0].records.push(
									operateTimeHrsInfo
								);

								let statement3 =
									"API.OperationalDetailsForAsset_Mileage " +
									"@assetId = " +
									this.assetId +
									", @startDateTimeMS = " +
									startDateAsUTC +
									", @endDateTimeMS = " +
									endDateAsUTC;

								this.dataService
									.SQLActionAsPromise(statement3)
									.then((dataRaw2: any) => {
										let milesDriven = [54280];
										// dataRaw2.forEach((record) => {
										// 	this.operationalDetails.push(record);
										// }
										// );

										let milesDrivenInfo = dataRaw2.find(
											(t) =>
												milesDriven.includes(
													parseInt(
														t.JBTStandardObservationId
													)
												)
										);
										if (_.isNil(milesDrivenInfo)) {
											milesDrivenInfo = {
												StandardName: "MilesDriven",
												FinalValue: "-",
												FinalName: "Miles Driven",
											};
										}

										// this.operationalDetails.milesDrivenInfo = '-';
										else {
											milesDrivenInfo.FinalName =
												"Miles Driven";
											milesDrivenInfo.FinalValue =
												parseFloat(
													milesDrivenInfo.FinalValue
												).toFixed(2);
										}
										milesDrivenInfo.Id = 10;

										this.operationalDetails[0].records.push(
											milesDrivenInfo
										);

										// this.operationalDetails[0].records.push(
										// 	{
										// 		StandardName: "ChargeLevel",
										// 		FinalValue: "-",
										// 		FinalName: "Charge Level",
										// 		Id: 11,
										// 	}
										// );
										// this.operationalDetails[0].records.push(
										// 	{
										// 		StandardName: "Used Hours",
										// 		FinalValue: "-",
										// 		FinalName: "Used Hours",
										// 		Id: 12,
										// 	}
										// );

										this.operationalDetails[0].records =
											this.operationalDetails[0].records.sort(
												(a, b) => {
													return a.Id - b.Id;
												}
											);
										this.operationalDetails[1].records =
											this.operationalDetails[1].records.sort(
												(a, b) => {
													return a.Id - b.Id;
												}
											);
										let statement4 =
											"API.OperationalDetailsForAsset_Alarms " +
											"@assetId = " +
											this.assetId +
											", @startDateTimeMS = " +
											startDateAsUTC +
											", @endDateTimeMS = " +
											endDateAsUTC;

										this.dataService
											.SQLActionAsPromise(statement4)
											.then((dataRaw3: any) => {
												this.historicAlarms = [];
												dataRaw3.forEach((record) => {
													console.log(record);
													let object: any = {
														AlarmName:
															record.AlarmName,
														Severity:
															this.dataService.getSeverityNameById(
																this.dataService
																	.cache
																	.tagsObject[
																	record.TagId
																]
																	.EffectiveSeverityLevelId
															),
														DurationInSeconds:
															record.DurationInSeconds,
														AlarmStartDate:
															this.determineAlarmDate(
																record.AlarmStartDate,
																this.siteObject
																	.UTCTimeOffset
															),
														AlarmEndDate:
															record.AlarmEndDate
																? this.determineAlarmDate(
																		record.AlarmEndDate,
																		this
																			.siteObject
																			.UTCTimeOffset
																  )
																: null,
													};

													this.historicAlarms.push(
														object
													);
												});

												if (
													this.historicAlarms.length >
													0
												) {
													//need to map to the infowindows
													this.realData.forEach(
														(record) => {
															let foundAlarms =
																this.historicAlarms.filter(
																	(alarm) => {
																		return (
																			(alarm.AlarmStartDate <=
																				record.Date &&
																				alarm.AlarmEndDate >=
																					record.Date) ||
																			alarm.AlarmEndDate ===
																				null
																		);
																	}
																);
															if (
																foundAlarms !==
																	undefined &&
																foundAlarms?.length >
																	0
															) {
																let highestAlarm =
																	this.dataService.returnHighestSeverityLevel(
																		this
																			.assetId,
																		foundAlarms
																	);
																record.highestAlert =
																	highestAlarm.Severity;
															}
														}
													);
												}
												// if(this.drawer2 && this.drawer2._opened === false){
												// 	// this.drawer2.toggle();

												// }

												if (
													this
														.listeningToSignalRMessages ===
													true
												) {
													this.listenToSignalRMessages(
														configuration.listenForSignalR
													);
												} else {
													if (
														this
															.signalRTagUpdateSubscription !==
														undefined
													) {
														this.signalRTagUpdateSubscription.unsubscribe();
													}
												}

												let statement5 =
													"API.OperationalDetailsForAsset_Alerts " +
													"@assetId = " +
													this.assetId +
													", @startDateTimeMS = " +
													startDateAsUTC +
													", @endDateTimeMS = " +
													endDateAsUTC;

												this.dataService
													.SQLActionAsPromise(
														statement5
													)
													.then(
														(
															alertsDataRaw: any
														) => {
															this.historicAlerts =
																[];
															alertsDataRaw.forEach(
																(record) => {
																	console.log(
																		"alert record ",
																		record
																	);
																	let object: any =
																		{
																			AlarmName:
																				record.AlarmName,
																			Severity:
																				record.Severity,
																			DurationInSeconds:
																				record.DurationInSeconds,
																			AlarmStartDate:
																				this.determineAlarmDate(
																					record.AlarmStartDate,
																					this
																						.siteObject
																						.UTCTimeOffset
																				),
																			AlarmEndDate:
																				record.AlarmEndDate
																					? this.determineAlarmDate(
																							record.AlarmEndDate,
																							this
																								.siteObject
																								.UTCTimeOffset
																					  )
																					: null,
																		};

																	this.historicAlerts.push(
																		object
																	);
																}
															);

															if (
																this
																	.historicAlerts
																	.length > 0
															) {
																//need to map to the infowindows
																this.realData.forEach(
																	(
																		record
																	) => {
																		let foundAlerts =
																			this.historicAlerts.filter(
																				(
																					alarm
																				) => {
																					return (
																						(alarm.AlarmStartDate <=
																							record.Date &&
																							alarm.AlarmEndDate >=
																								record.Date) ||
																						alarm.AlarmEndDate ===
																							null
																					);
																				}
																			);
																		// if ( foundAlerts !== undefined && foundAlerts?.length > 0 ) {
																		// 	let highestAlarm = this.dataService.returnHighestSeverityLevel(this.assetId, foundAlerts);
																		// 	record.highestAlert = highestAlarm.Severity;
																		// }
																	}
																);
															}
															// if(this.drawer2 && this.drawer2._opened === false){
															// 	// this.drawer2.toggle();

															// }
															if (
																this
																	.alertDataGrid
															) {
																this.alertDataGrid.gridDataSubject.next(
																	this
																		.historicAlerts
																);
															}
															this.dataDisplay =
																true;
															this.materialTabSelected = 1;
															this.isLoading =
																false;
															if (this.showMap) {
																this.renderMap();
															}
															this.playbackHasBeenLoadedOnce =
																true;

															if (
																this
																	.listeningToSignalRMessages ===
																true
															) {
																this.listenToSignalRMessages(
																	configuration.listenForSignalR
																);
															} else {
																if (
																	this
																		.signalRTagUpdateSubscription !==
																	undefined
																) {
																	this.signalRTagUpdateSubscription.unsubscribe();
																}
															}
														}
													);
											});
									});
							});
					});
			}
		});
	}

	renderMap() {
		console.log(this.realData);

		// // Create an array of alphabetical characters used to label the markers.
		// const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		// // Add some markers to the map.

		let markers = [];
		// markers = this.realData.map((position, i) => {
		// 	let pos = { lat: position.Latitude, lng: position.Longitude };
		// 	console.log(position)
		// 	const marker = new google.maps.Marker({
		// 		position: pos,
		// 	});
		// 	return marker;
		// });
		this.newLatLngBounds = new google.maps.LatLngBounds();

		this.realData.forEach((dataObj, index) => {
			dataObj.JavascriptDate = this.determineAlarmDate(
				new Date(dataObj.Date),
				this.siteObject.UTCTimeOffset
			);
			let icon: any = {};
			let objIns = {
				lat: dataObj.Latitude,
				lng: dataObj.Longitude,
			};
			var gseLatLng = new google.maps.LatLng(
				dataObj.Latitude,
				dataObj.Longitude
			);
			this.newLatLngBounds.extend(gseLatLng);
			let size = new google.maps.Size(41, 41);
			let origin = new google.maps.Point(0, 0);

			if (index === 0) {
				icon.url =
					"http://maps.google.com/mapfiles/ms/micons/green.png";
				icon.origin = new google.maps.Point(0, 0);
				icon.anchor = new google.maps.Point(10, 10);
			} else {
				icon.url = this.mapsService.returnPlaybackMarker(
					dataObj.highestAlert
				);

				icon.scaledSize = new google.maps.Size(15, 15);
				icon.origin = new google.maps.Point(0, 0);
				icon.anchor = new google.maps.Point(9, 9);
			}
			dataObj.marker = new google.maps.Marker({
				position: objIns,
				// title: dataObj.Speed.toString(),
				icon: icon,
				optimized: false,
			});
			dataObj.marker.setMap(this.mapForBreadcrumbs);
			markers.push(dataObj.marker);
		});
		let configuration: any = {
			zoom: 12,
		};
		if (this.realData.length > 0) {
			configuration.center = {
				lat: this.realData[0].Latitude,
				lng: this.realData[0].Longitude,
			};
		}
		this.mapForBreadcrumbs = new google.maps.Map(
			document.getElementById(this.mapDiv),
			// configuration
			this.mapsService.loadMapConfiguration(configuration)
		);

		// this.mapForBreadcrumbs.fitBounds(this.newLatLngBounds);

		// setTimeout(() => {
		// 	let position = locations[0];
		// 	let marker = new google.maps.Marker({
		// 		position: position,
		// 		map: map,
		// 		zIndex: Number(google.maps.Marker.MAX_ZINDEX),
		// 	});
		// }, 1000);

		setTimeout(() => {
			let test = document.getElementById(this.mapDiv);
			if (test !== null) {
				let drivePathArray = [];
				let panControl = document.createElement("div");
				panControl.setAttribute(
					"id",
					"directionalMappingForBreadcrumbs"
				);

				var directions = [
					{ text: "North", character: "↑" },
					{ text: "West", character: "←" },
					{ text: "East", character: "→" },
					{ text: "South", character: "↓" },
				];

				var pan = [];

				this.legend = document.createElement("div");
				let legendName = "legend";
				if (
					this.widgetObject !== undefined &&
					this.widgetObject.WidgetId !== undefined
				) {
					legendName = legendName + this.widgetObject.WidgetId;
				}
				this.legend.classList.add("gseLegend");
				this.legend.setAttribute("id", legendName);
				let row1 = document.createElement("div");

				let row2 = document.createElement("div");

				let row3 = document.createElement("div");

				row1.innerHTML = `<button style="padding: 3px 6px; height: 25px; display: block; margin: 0 auto;" class="jbt-btn btn-sm" id="northButton"> &nbsp ${directions[0].character} &nbsp </button>`;

				row2.innerHTML = `<button style="padding: 3px 6px; height: 25px; display: inline-block;" class="jbt-btn btn-sm" id="westButton">${directions[1].character}</button> <button style="padding: 3px 6px; height: 25px; display: inline-block" class="jbt-btn btn-sm" id="eastButton">${directions[2].character}</button>`;

				row3.innerHTML = `<button style="padding: 3px 6px; height: 25px; display: block; margin: 0 auto;" class="jbt-btn btn-sm" id="southButton"> &nbsp ${directions[3].character} &nbsp</button>`;

				panControl.appendChild(row1);

				panControl.appendChild(row2);

				panControl.appendChild(row3);
				console.log(this.realData);
				let styleControl: any;

				this.mapForBreadcrumbs.addListener("tilesloaded", () => {
					// do something only the first time the map is loaded
					if (this.tilesHaveBeenLoadedFirstTime === false) {
						setTimeout(() => {
							this.mapForBreadcrumbs.controls[
								google.maps.ControlPosition.TOP_CENTER
							].push(panControl);

							var north = document.getElementById("northButton");
							var west = document.getElementById("westButton");
							var east = document.getElementById("eastButton");
							var south = document.getElementById("southButton");
							var panAmount = 100;
							north.addEventListener("click", () => {
								this.mapForBreadcrumbs.panBy(0, -panAmount);
							});
							west.addEventListener("click", () => {
								this.mapForBreadcrumbs.panBy(-panAmount, 0);
							});
							east.addEventListener("click", () => {
								this.mapForBreadcrumbs.panBy(panAmount, 0);
							});
							south.addEventListener("click", () => {
								this.mapForBreadcrumbs.panBy(0, panAmount);
							});
							let id = "legend";
							if (
								this.widgetObject !== undefined &&
								this.widgetObject.WidgetId !== undefined
							) {
								id = id + this.widgetObject?.WidgetId;
							}

							let accountType =
								this.dataService.cache.jbtStandardObservationSeverityLevels.find(
									(severity) => {
										return severity.Name === "Critical";
									}
								);
							let itemsToIterate: any;
							if (accountType !== undefined) {
								itemsToIterate =
									this.gseService.legendIconsBreadcrumbs;
							} else {
								itemsToIterate =
									this.gseService
										.legendIconsBreadcrumbsVocational;
							}

							for (var key in itemsToIterate) {
								var type = itemsToIterate[key];
								var name = type.name;
								var icon = type.icon;
								var div = document.createElement("div");
								div.innerHTML =
									'<img style="height: 20px" src="' +
									icon +
									'"> ' +
									'<span style="font-size: 12px; margin-left: 5px;" class="gseLegendLabel">' +
									name +
									"</span>";
								this.legend.appendChild(div);
							}

							let focusDiv = document.createElement("div");
							let focusedName = "Focused Marker";
							let focusedIcon =
								"http://maps.google.com/mapfiles/ms/micons/orange.png";
							focusDiv.innerHTML =
								'<img style="height: 20px" src="' +
								focusedIcon +
								'"> ' +
								'<span style="font-size: 12px; margin-left: 5px;" class="gseLegendLabel">' +
								focusedName +
								"</span>";
							this.legend.appendChild(focusDiv);

							let collectionDiv = document.createElement("div");
							let collectionName = "Collection of Markers";
							let collectionIcon =
								"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png";
							collectionDiv.innerHTML =
								'<img style="height: 20px" src="' +
								collectionIcon +
								'"> ' +
								'<span style="font-size: 12px; margin-left: 5px;" class="gseLegendLabel">' +
								collectionName +
								"</span>";
							this.legend.appendChild(collectionDiv);
							// if (speedName !== "") {
							// 	for (var key in this.gseService
							// 		.legendSpeedsBreadcrumbs) {
							// 		var type =
							// 			this.gseService.legendSpeedsBreadcrumbs[
							// 				key
							// 			];
							// 		var name = type.name;
							// 		var color = type.color;
							// 		var div = document.createElement("div");
							// 		div.innerHTML = `<br><hr style="border: 5px solid ${color}; width: 30px; display: inline-block; margin-right: 5px; background-color: ${color}"><div><span style="color: black; font-size: 12px;">${name}</span></div>`;
							// 		this.legend.appendChild(div);
							// 	}
							// }
							this.legend.hidden = !this.showMapLegend;

							this.mapForBreadcrumbs.controls[
								google.maps.ControlPosition.LEFT_BOTTOM
							].push(this.legend);
							// this.mapForBreadcrumbs.controls[
							// 	google.maps.ControlPosition.BOTTOM_CENTER
							// ].push(styleControl);
							this.tilesHaveBeenLoadedFirstTime = true;
						}, 2000);
					}
				});

				this.markerclusterer = new markerClusterer.MarkerClusterer({
					map: this.mapForBreadcrumbs,
					markers: markers,
					renderer: this.mapsService.renderer,
				});
				this.hasMapBeenLoaded = true;

				this.isLoading = false;
				setTimeout(() => {
					if (this.tagDataGrid) {
						this.tagDataGrid.gridDataSubject.next(this.realData);
					}
				}, 1000);
			}

			this.determineToDrawChart(false);
		}, 500);
	}

	determineToDrawChart(redraw) {
		// if(this.dataDisplay === true && this.chart === undefined && this.drawer2._opened === true && this.realData.length > 0){
		// 	this.drawChart();
		// }
		if (
			this.chart === undefined &&
			this.realData.length > 0 &&
			redraw === false
		) {
			this.drawChart(Global.Theme === "light" ? false : true);
		} else if (redraw && this.chart !== undefined) {
			this.drawChart(Global.Theme === "light" ? false : true);
		}
	}

	drawChart(darkTheme) {
		console.log(this.realData);
		let speedData = [];
		let engineSpeedData = [];
		let fuelLevelData = [];
		let powerOnData = [];
		// this.chartSeriesData = [];
		// this.gridSettings.columnsConfig.forEach((column) => {
		// 	if (column.field !== "Id" && column.title !== "Date") {
		// 		this.chartSeriesData.push({
		// 			name: column.title,
		// 			jbtObsId: column.field.split("$")[1],
		// 			data: [],
		// 			Severity: column.Severity,
		// 			ValueWhenActive: column.ValueWhenActive,
		// 		});
		// 	}
		// });
		// this.realData.forEach((record) => {
		// 	let dateinMS = record.JavascriptDate.getTime();

		// 	this.gridSettings.columnsConfig.forEach((column) => {
		// 		if (column.field !== "Id" && column.title !== "Date") {
		// 			let value = record[column.field];
		// 			if (value !== undefined) {
		// 				let dataItem: any = [dateinMS, parseInt(value)];
		// 				let series = this.chartSeriesData.find((series) => {
		// 					return (
		// 						series.jbtObsId === column.field.split("$")[1]
		// 					);
		// 				});
		// 				series.data.push(dataItem);
		// 			}
		// 		}
		// 	});

		// });

		let plotBands = [];
		// this.chartSeriesData.forEach((series) => {
		// 	let alarmStart: number;
		// 	let alarmStop: number;
		// 	if (
		// 		series.Severity !== undefined &&
		// 		series.Severity !== null &&
		// 		series.Severity !== "Informational"
		// 	) {
		// 		series.data.forEach((dataPoint, index) => {
		// 			if (
		// 				dataPoint[1] === series.ValueWhenActive &&
		// 				(index === 0 ||
		// 					series.data[index - 1][1] !==
		// 						series.ValueWhenActive)
		// 			) {
		// 				//alarm has started. find where it stops.

		// 				alarmStart = dataPoint[0];
		// 			}

		// 			if (
		// 				alarmStart !== undefined &&
		// 				(dataPoint[1] !== series.ValueWhenActive ||
		// 					index === series.data.length - 1)
		// 			) {
		// 				//alarm has stopped
		// 				alarmStop = dataPoint[0];
		// 				plotBands.push({
		// 					from: alarmStart,
		// 					to: alarmStop,
		// 					color:
		// 						series.Severity === "Critical"
		// 							? "rgba(255, 0, 0, 0.5)"
		// 							: series.Severity === "Alarm"
		// 							? "rgba(255, 0, 0, 0.2)"
		// 							: "rgba(255, 255, 0, 0.2)",
		// 					label: {
		// 						text:
		// 							series.name +
		// 							" " +
		// 							series.Severity +
		// 							" Active",
		// 					},
		// 					// color: 'red'
		// 					// yAxis: series.yAxis,
		// 				});
		// 				alarmStart = undefined;
		// 			}
		// 		});
		// 	}
		// });
		let yaxisCopies = _.cloneDeep(this.chartMetaData.yAxisInstances);
		yaxisCopies.forEach((axis) => {
			axis.title = {
				text: "",
				style: {
					color: Global.Theme === "light" ? "#212427" : "white",
					// fontSize: "12px",
				},
			};
		});

		let plotLines = [];
		this.historicAlarms.forEach((alarm, index) => {
			let plotLine = {
				color: this.dataService.returnSeverityColor(alarm.Severity),
				zIndex: 1,

				from: alarm.AlarmStartDate,
				to: alarm.AlarmStartDate,
				label: {
					text: "Fault Start: " + alarm.AlarmName,
					style: {
						display: "none",
					},
					align: "left",
				},
				events: {
					click: function (e) {
						console.log(this);
					},
					mouseover: function (e) {
						this.label.element.style.display = "block";
					},
					mouseout: function (e) {
						this.label.element.style.display = "none";
					},
				},
			};

			let plotBand = {
				from: alarm.AlarmStartDate,
				to: alarm.AlarmEndDate ? alarm.AlarmEndDate : new Date(),
				color:
					alarm.Severity === "Critical" || alarm.Severity === "Red"
						? "rgba(255, 0, 0, 0.5)"
						: alarm.Severity === "Alarm"
						? "rgba(255, 0, 0, 0.2)"
						: alarm.Severity === "Amber"
						? "rgba(255, 255, 191, 0)"
						: "rgba(255, 255, 0, 0.2)",
				zIndex: 1,

				label: {
					text: "Alarm Start: " + alarm.AlarmName,
					style: {
						display: "none",
					},
					align: "left",
				},
				events: {
					click: function (e) {
						console.log(this);
					},
					mouseover: function (e) {
						this.label.element.style.display = "block";
					},
					mouseout: function (e) {
						this.label.element.style.display = "none";
					},
				},
			};
			plotLines.push(plotLine);
			plotBands.push(plotBand);
		});

		this.historicAlerts.forEach((alarm, index) => {
			let plotLine = {
				color: this.dataService.returnSeverityColor(alarm.Severity),
				zIndex: 1,

				from: alarm.AlarmStartDate,
				to: alarm.AlarmStartDate,
				label: {
					text: "Fault Start: " + alarm.AlarmName,
					style: {
						display: "none",
					},
					align: "left",
				},
				events: {
					click: function (e) {
						console.log(this);
					},
					mouseover: function (e) {
						this.label.element.style.display = "block";
					},
					mouseout: function (e) {
						this.label.element.style.display = "none";
					},
				},
			};

			let plotBand = {
				from: alarm.AlarmStartDate,
				to: alarm.AlarmEndDate ? alarm.AlarmEndDate : new Date(),
				color:
					alarm.Severity === "Critical" || alarm.Severity === "Red"
						? "rgba(255, 0, 0, 0.5)"
						: alarm.Severity === "Alarm"
						? "rgba(255, 0, 0, 0.2)"
						: alarm.Severity === "Amber"
						? "rgba(255, 255, 191, 0)"
						: "rgba(255, 255, 0, 0.2)",
				zIndex: 1,

				label: {
					text: "Alarm Start: " + alarm.AlarmName,
					style: {
						display: "none",
					},
					align: "left",
				},
				events: {
					click: function (e) {
						console.log(this);
					},
					mouseover: function (e) {
						this.label.element.style.display = "block";
					},
					mouseout: function (e) {
						this.label.element.style.display = "none";
					},
				},
			};
			plotLines.push(plotLine);
			plotBands.push(plotBand);
		});

		let chartOptions: any = {
			chart: {
				zoomType: "x",
				backgroundColor: darkTheme === true ? "rgb(39,41,61)" : "white",
				style: {
					fontFamily: "Poppins, sans-serif",
					color:
						darkTheme === true
							? this.sassHelper.readProperty(
									"body-text-color-light"
							  )
							: this.sassHelper.readProperty(
									"body-text-color-dark"
							  ),
				},
				events: {
					load: this.chartService.updateHighchartLegendLabel,
				},
			},
			rangeSelector: {
				// labelStyle: {
				// 	color:
				// 		darkTheme === true
				// 			? this.sassHelper.readProperty(
				// 					"body-text-color-light"
				// 			  )
				// 			: this.sassHelper.readProperty(
				// 					"body-text-color-dark"
				// 			  ),
				// },
				enabled: false,
				allButtonsEnabled: true,
				buttons: [
					{
						type: "all",
						text: "All",
					},
				],
				buttonTheme: {
					width: 60,
				},
				imputStyle: {
					color: Global.Theme === "light" ? "#212427" : "white",
				},
				selected: 5,
			},

			subtitle: {
				align: "left",
				style: {
					fontFamily: "Roboto Condensed",
				},
			},
			exporting: {
				sourceWidth: 1800,
				sourceHeight: 1200,
				allowHTML: true,
				scale: 1,
			},

			navigator: {
				enabled: true,
				handles: {
					// backgroundColor: darkTheme === true ? "",
					borderColor: "red",
				},
				adaptToUpdatedData: true,
			},
			legend: {
				enabled: this.showLegend,
				itemStyle: {
					color:
						darkTheme === true
							? this.sassHelper.readProperty(
									"body-text-color-light"
							  )
							: this.sassHelper.readProperty(
									"body-text-color-dark"
							  ),
				},
			},
			credits: { enabled: false },
			series: this.chartWidgetSeries,

			plotOptions: {
				series: {
					turboThreshold: 1000,
					showInNavigator: true,
					marker: {
						enabled: false,
					},
					states: {
						hover: {
							enabled: false,
						},
					},
					dataGrouping: {
						approximation: "high",
					},
					point: {
						events: {
							click: (item: any) => {
								this.setPoint(item);
								console.log(item);
								console.log(this);
							},
						},
					},
					label: {
						enabled: this.showYAxisTitles,
					},
				},
			},
			tooltip: {
				split: true,
				shared: false,
				followPointer: true,
				//TODO use the scope of this to determine if it is playing. if it is, then override the tooltip to be playback values. if it is not, then use the default tooltip.

				// formatter: function () {
				// 	var series = this.series.chart.series,
				// 		result =
				// 			'<span style="color:' +
				// 			this.point.color +
				// 			'">\u25CF</span> ' +
				// 			new Date(this.point.x).toLocaleString() +
				// 			"<br/>" +
				// 			this.series.name +
				// 			": <b>" +
				// 			this.point.y +
				// 			"</b><br/>";

				// 	series.forEach(function (s) {
				// 		if (s !== this.series && s.name !== "Navigator 1") {
				// 			result +=
				// 				'<span style="color:' +
				// 				s.points[this.point.index].color +
				// 				'">\u25CF</span><span style="color: #c6c6c6"> ' +
				// 				s.name +
				// 				": " +
				// 				s.points[this.point.index].y +
				// 				"</span><br/>";
				// 		}
				// 	}, this);

				// 	return result;
				// },
				pointFormatter: (function (component) {
					return function (e: any) {
						let tooltipText = "";
						let playback = true;
						let manualSliding = true;
						if (component.programticallyShowingTooltip) {
							let underscoreName = this.series.name.replace(
								/ /g,
								"_"
							);
							let foundRealDataValue =
								component.realData[
									component.sliderValuePlayback - 1
								][underscoreName];
							tooltipText +=
								'<span style="color:' +
								this.color +
								'">' +
								this.series.name +
								"</span>: <b>" +
								(this.series.options.isDigital
									? component._decimalPlacesPipe.transform(
											foundRealDataValue,
											0,
											this.series.options
												.JBTStandardObservationId
									  )
									: component._decimalPlacesPipe.transform(
											foundRealDataValue,
											1,
											this.series.options
												.JBTStandardObservationId
									  )) +
								"</b><br/></span>";
						}

						// if (Global.User.DebugMode) {
						// 	let chartSeriesFound = chartSeries.find(
						// 		(x, index) => index === this.series.index
						// 	);

						// 	tooltipText +=
						// 		'<span style="color:' +
						// 		this.color +
						// 		'"><strong>TagId:</strong> ' +
						// 		chartSeriesFound.tagId +
						// 		" <strong>LongTagName:</strong> " +
						// 		chartSeriesFound.longTagName +
						// 		" </span><br/>";
						// }
						else {
							tooltipText +=
								'<span style="color:' +
								this.color +
								'">' +
								this.series.name +
								"</span>: <b>" +
								(this.series.options.isDigital
									? component._decimalPlacesPipe.transform(
											this.y,
											0,
											this.series.options
												.JBTStandardObservationId
									  )
									: component._decimalPlacesPipe.transform(
											this.y,
											1,
											this.series.options
												.JBTStandardObservationId
									  )) +
								"</b><br/></span>";
						}

						return tooltipText;
					};
				})(this),

				// formatter: (function (component) {
				// 	return function (e: any) {
				// 		let playback = true;
				// 		if(playback === true){

				// 		}
				// 		var series = this.series.chart.series,
				// 				result =
				// 					'<span style="color:' +
				// 					this.point.color +
				// 					'">\u25CF</span> ' +
				// 					new Date(this.point.x).toLocaleString() +
				// 					"<br/>" +
				// 					this.series.name +
				// 					": <b>" +
				// 					this.point.y +
				// 					"</b><br/>";

				// 			series.forEach(function (s) {
				// 				if (s !== this.series && !s.name.includes("Navigator")) {
				// 					result +=
				// 						'<span style="color:' +
				// 						s.points[this.point.index].color +
				// 						'">\u25CF</span><span style="color: #c6c6c6"> ' +
				// 						s.name +
				// 						": " +
				// 						s.points[this.point.index].y +
				// 						"</span><br/>";
				// 				}
				// 			}, this);

				// 			return result;
				// 	};
				// })(this),

				crosshairs: {
					color: "red",
					dashStyle: "solid",
					width: 2,
				},
				enabled: true,
			},
			yAxis: yaxisCopies,
			xAxis: {
				type: "datetime",
				dateTimeLabelFormats: {
					millisecond: "%Y-%m-%d<br/>%H:%M:%S:%L",
					second: "%Y-%m-%d<br/>%H:%M:%S",
					minute: "%Y-%m-%d<br/>%H:%M:%S",
					hour: "%Y-%m-%d<br/>%H:%M:%S",
					day: "%Y-%m-%d<br/>%H:%M:%S",
					week: "%Y-%m-%d<br/>%H:%M:%S",
					month: "%Y-%m-%d<br/>%H:%M:%S",
					year: "%Y-%m-%d<br/>%H:%M:%S",
					gridLineColor: "#424242",
					gridLineWidth: 1,
					minorGridLineColor: "#424242",
					minoGridLineWidth: 0.5,
					tickColor: "#424242",
					minorTickColor: "#424242",
					lineColor: "#424242",
				},
				labels: {
					style: {
						color:
							darkTheme === true
								? this.sassHelper.readProperty(
										"body-text-color-light"
								  )
								: this.sassHelper.readProperty(
										"body-text-color-dark"
								  ),
					},
				},
				events: {
					afterSetExtremes:
						this.chartService.updateHighchartLegendLabel,
				},
				ordinal: false,
				// tickInterval: 8000,
				minRange: 20000,
				// plotBands: [
				//   {
				//     // mark the weekend
				//     //color: '#FCFFC5', light yellw
				//     color: "#f8f8f8",
				//     from: Date.UTC(2000, 0, 2),
				//     to: Date.UTC(2999, 0, 4)
				//   }
				// ]
			},
		};

		if (!this.showTooltip) {
			chartOptions.tooltip.formatter = function () {
				return "";
			};
		}
		if (plotBands.length > 0 && this.showPlotBands) {
			chartOptions.xAxis.plotBands = plotBands;
		}
		if (plotLines.length > 0 && this.showPlotLines) {
			chartOptions.xAxis.plotLines = plotLines;
		}

		let chartDivId = "breadcrumbsChart";
		if (
			this.widgetObject !== undefined &&
			this.widgetObject.WidgetId !== undefined
		) {
			chartDivId = chartDivId + this.widgetObject.WidgetId;
		}
		this.zone.runOutsideAngular(() => {
			this.chart = Highcharts.stockChart(chartDivId, chartOptions);
			console.log(this.chart);
			console.log(this.chartWidgetSeries);
		});
		this.showChartTooltip();
	}

	concatAlertsForInfowindow(record, signalR) {
		if (record.highestAlert !== "N/A" && signalR == false) {
			//find the alerts that are active in the this.historicAlarms array
			let alerts = this.historicAlarms.filter((alarm) => {
				return (
					(alarm.AlarmStartDate <= record.Date &&
						alarm.AlarmEndDate >= record.Date) ||
					alarm.AlarmEndDate === null
				);
			});
			let htmlString = "<br><div>Alarms: </div>";
			alerts.forEach((alert, index) => {
				let alertString = `<br><div style="padding-left: 5px">Severity: ${alert.Severity}</div><div style="padding-left: 5px">StartDate: ${alert.AlarmStartDate}</div><div style="padding-left: 5px">EndDate: ${alert.AlarmEndDate}</div><div style="padding-left: 5px">Name: ${alert.AlarmName}</div>`;

				htmlString = htmlString + alertString;
			});
			return htmlString;
		} else if (record.highestAlert !== "N/A" && signalR == true) {
			//find the alerts that are active in the this.historicAlarms array

			let htmlString = "<br><div>Alarms: </div>";
			this.dataService.cache.assetsObject[
				this.assetId
			].ActiveAlarmTags.forEach((alert, index) => {
				let alertString = `<br><div style="padding-left: 5px">Severity: ${alert.Severity}</div><div style="padding-left: 5px">StartDate: ${alert.JavascriptDate}</div><div style="padding-left: 5px">Name: ${alert.Name}</div>`;

				htmlString = htmlString + alertString;
			});
			return htmlString;
		} else {
			return;
		}
		// if (record.ActiveAlerts?.length > 0) {
		// 	let htmlString = "<br><div>Alarms: </div>";
		// 	record.ActiveAlerts.forEach((alert, index) => {
		// 		let alertString = `<br><div style="padding-left: 5px">Severity: ${alert.Severity}</div><div style="padding-left: 5px">Date: ${alert.JavascriptDate}</div><div style="padding-left: 5px">Name: ${alert.Value}</div>`;

		// 		htmlString = htmlString + alertString;
		// 	});

		// 	return htmlString;
		// } else {
		// 	return;
		// }
	}

	setPoint(item: any) {
		//TODO send the event to the child playback
		// this.controlPlayback('Pause');
		const findClosestIndex = this.realData.findIndex((object) => {
			console.log(object.Date.getTime());
			return item.point.x <= object.Date.getTime();
		});
		this.newIndex(findClosestIndex);
	}

	public onRightClickSelect({ dataItem, item }) {
		if (item === "Show Infowindow") {
			this.openInfoWindow(dataItem);
		}
	}

	panToMarker(dataObj, index) {
		//do we need to run a function? Could we just move to marker lat long?
		let position = new google.maps.LatLng(
			dataObj.Latitude,
			dataObj.Longitude
		);
		this.mapForBreadcrumbs.panTo(position);
		// this.mapForBreadcrumbs.panTo(dataObj.marker.getPosition());
		// dataObj.marker.icon.url = "http://maps.google.com/mapfiles/ms/micons/purple.png"

		// // if (this.previousMarker !== undefined) {
		// // 	this.previousMarker.marker.icon.url = "http://maps.google.com/mapfiles/ms/micons/red.png";
		// // }
		// this.previousMarkerIndex = index;
		// this.previousMarker = this.realData[this.previousMarkerIndex];
	}

	openInfoWindow(dataObj) {
		this.selectedRowArray = [];
		dataObj.infoWindow.setMap(this.mapForBreadcrumbs);
		dataObj.marker.setMap(this.mapForBreadcrumbs);
		dataObj.infoWindow.open(this.mapForBreadcrumbs, dataObj.marker);
		this.selectedRowArray.push(dataObj.Id);
		// setTimeout(() => {
		// 	const scrollTo = document.querySelector('.k-state-selected');
		// 	if (scrollTo) {
		// 		scrollTo.scrollIntoView();
		// 	}
		// });
	}

	addNewPoint(signalRData) {
		setTimeout(() => {
			let manipulatedObj: any = {
				Index: this.realData.length,

				Date: this.determineAlarmDate(
					new Date(),
					this.siteObject.UTCTimeOffset
				),
				highestAlert: "N/A",
			};

			this.chartWidgetSeries.forEach((cs) => {
				let tag = this.dataService.cache.assets
					.find((asset) => {
						return asset.Id === parseInt(this.assetId);
					})
					?.Tags.find(
						(t) =>
							t.JBTStandardObservationId ==
							cs.JBTStandardObservationId
					);
				if (tag !== undefined) {
					tag.Value = parseFloat(tag.Value);
					let fieldName = cs.jbtStandardObservationName.replaceAll(
						" ",
						"_"
					);
					manipulatedObj[fieldName] = tag.Value;
				}
			});
			let asset = this.dataService.cache.assets.find((asset) => {
				return asset.Id === parseInt(this.assetId);
			});

			let alarmsLength =
				this.dataService.cache.assetsObject[this.assetId]
					.ActiveAlarmTags.length;

			if (alarmsLength > 0) {
				//determine highest alarm from activealarmstagsarray
				let highestAlarm = this.dataService.returnHighestSeverityLevel(
					this.assetId
				);
				console.log(highestAlarm);
				manipulatedObj.highestAlert = highestAlarm.Severity;
			}

			if (this.realData.length > 0) {
				// if (
				// 	this.realData[this.realData.length - 1]
				// 		.Latitude !== lat.Value ||
				// 	this.realData[this.realData.length - 1]
				// 		.Longitude !== long.Value
				// ) {
				//TODO re-add this code back
				this.addPointToPolyline(manipulatedObj);
				// }
			} else if (this.realData.length === 0) {
				this.addPointToPolyline(manipulatedObj);
			}
			// }
		}, 500);
	}

	listenToSignalRMessages(listenForSignalR: boolean) {
		if (listenForSignalR === true) {
			if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = true;
			}

			this.signalRTagUpdateSubscription =
				this.signalRCore.broadcastMessages$
					.pipe(filter((msg: any) => msg.code == "o"))
					.subscribe((newTag: any) => {
						let signalRData =
							this.dataService.GetJsonFromSignalR(newTag);
						if (this.tagIdList.includes(signalRData.i)) {
							this.addNewPoint(signalRData);
						}
					});
		} else {
			if (this.signalRTagUpdateSubscription !== undefined) {
				this.signalRTagUpdateSubscription.unsubscribe();
			} else {
				if (this.widgetObject !== undefined) {
					this.widgetObject.isDisplayDataLive = false;
				}
			}
		}
	}

	minutesOutCalculation(direction, minutes) {
		if (direction === "start") {
			let endTemplate = new Date(this.startDateTime);
			endTemplate.setMinutes(endTemplate.getMinutes() + minutes);
			this.endDateTime = endTemplate;
		} else if (direction === "end") {
			let startTemplate = new Date(this.endDateTime);
			startTemplate.setMinutes(startTemplate.getMinutes() - minutes);
			this.startDateTime = startTemplate;
		}
		this.form.setValue({
			startDateTime: this.startDateTime,
			endDateTime: this.endDateTime,
			timeBack: this.form.value?.timeBack
				? parseInt(this.form.value.timeBack)
				: minutes,
			template: this.form.value.template,
		});
	}

	GetStandardIdsForTemplate(template) {
		var jbtStandardObservationIdWidgetTemplatesForUser =
			this.dataService.cache.jbtStandardObservationIdWidgetTemplates
				.filter((t: any) => {
					return t.JBTStandardObservationIdTemplateId == template.Id;
				})
				.map((t: any) => {
					return t.Id;
				});

		var jbtStandardObservationIdWidgetTemplateJBTStandardObservationsForUser =
			this.dataService.cache.jbtStandardObservationIdWidgetTemplate_JBTStandardObservations
				.filter((o: any) => {
					return jbtStandardObservationIdWidgetTemplatesForUser.includes(
						o.JbtStandardObservationIdWidgetTemplateId
					);
				})
				.map((t: any) => {
					return t.JBTStandardObservationId;
				});

		return jbtStandardObservationIdWidgetTemplateJBTStandardObservationsForUser;
	}

	editTags() {
		if (this.dataObject != undefined)
			this.dataObject.AssetId = this.assetId;
		const dialog = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? "50%" : "50%",
			height: Global.isMobile ? "60%" : "75%",
			data: {
				widgetNameDisplay: "JbtStandardObservationIdTemplateTags",
				WidgetName: "jbtStandardObservationIdTemplateTagsComponent",
				widgetObject: this.widgetObject
					? this.widgetObject
					: this.dataObject
					? this.dataObject
					: this.inputAssetObject,
				selectedTags: this.selectedTags,
			},

			maxWidth: "100vw",
			maxHeight: "100vh",
		});

		dialog.afterClosed().subscribe((result) => {
			console.log(result);
			this.selectedTags = result?.Tags.filter((t: any) => {
				return t.isTagSelected == true;
			}).map((t: any) => {
				return t.JBTStandardObservation.Id;
			});
			console.log("selectedTags..", this.selectedTags);
		});
	}
	createTemplate(form) {
		//  set existing TemplateId
		if (this.widgetObject) {
			this.widgetObject.TemplateId = this.currentTemplate?.Id;
		} else if (this.dataObject) {
			this.dataObject.TemplateId = this.currentTemplate?.Id;
		} else if (this.inputAssetObject) {
			this.inputAssetObject.WidgetAssetId = this.inputAssetObject.Id;
			this.inputAssetObject.TemplateId = this.currentTemplate?.Id;
		}

		const dialog = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? "50%" : "50%",
			height: Global.isMobile ? "50%" : "50%",
			data: {
				widgetNameDisplay: "JbtStandardObservationIdTemplate",
				WidgetName: "jbtStandardObservationIdTemplateComponent",
				widgetObject: this.widgetObject
					? this.widgetObject
					: this.dataObject
					? this.dataObject
					: this.inputAssetObject,
				selectedTags: this.selectedTags,
			},

			maxWidth: "100vw",
			maxHeight: "100vh",
		});

		dialog.afterClosed().subscribe((result) => {
			console.log("widgetObject", this.widgetObject);

			this.templatesForUser =
				this.dataService.cache.jbtStandardObservationIdTemplates.filter(
					(p: any) => {
						return (
							//p.CanvasTemplateTypeId == 2 && // Get the canvas templates for breadcrumbs
							p.CreatorUserId ==
							Global.User.currentUser.Id.toString()
						);
					}
				);

			// var selectedTemplate =
			// 	this.dataService.cache.jbtStandardObservationIdTemplates.filter((p: any) => {
			// 		return (
			// 			p.Id ==
			// 			(this.widgetObject
			// 				? this.widgetObject.TemplateId
			// 				: this.dataObject
			// 				? this.dataObject.TemplateId
			// 				: this.inputAssetObject.TemplateId)
			// 		);
			// 	});

			// if (selectedTemplate.length > 0) {
			// 	this.currentTemplate = selectedTemplate[0];
			// 	this.selectedTemplateId = this.currentTemplate.Id;

			// 	this.CreateJBTStandardObservationIdWidgetsForTemplate(this.currentTemplate);

			// 	this.GetStandardObservationsForSelectedTemplate();

			// 	this.existingTemplatesForUser = true;
			// }
		});
	}

	applyDateSelection() {
		console.log(this.form);
		this.endDateTime = new Date();
		let startTemplate = new Date();
		startTemplate.setMinutes(
			startTemplate.getMinutes() - parseInt(this.form.value.timeBack)
		);

		this.startDateTime = startTemplate;
		this.form.setValue({
			startDateTime: this.startDateTime,
			endDateTime: this.endDateTime,
			timeBack: parseInt(this.form.value.timeBack),
			template: this.form.value.template,
		});
		if (this.determineIfLive() === true) {
			this.listeningToSignalRMessages = true;
		} else {
			this.listeningToSignalRMessages = false;
		}
	}

	setStartEndTime(direction) {
		if (direction === "+") {
			let date = new Date();
			// maxDate.setDate(maxDate.getDate() - 1)
			let fourHoursAhead = _.cloneDeep(this.startDateTime);
			fourHoursAhead.setHours(fourHoursAhead.getHours() + 4);
			if (fourHoursAhead > date) {
				this.endDateTime = new Date();
				this.form.setValue({
					startDateTime: this.startDateTime,
					endDateTime: new Date(),
					template: this.form.value.template,
				});
			} else {
				this.endDateTime = fourHoursAhead;
				this.form.setValue({
					startDateTime: this.startDateTime,
					endDateTime: fourHoursAhead,
					template: this.form.value.template,
				});
			}
		} else if (direction === "-") {
			let fourHoursBehind = _.cloneDeep(this.endDateTime);
			fourHoursBehind.setHours(fourHoursBehind.getHours() - 4);
			this.startDateTime = fourHoursBehind;
			this.form.setValue({
				startDateTime: this.startDateTime,
				endDateTime: this.endDateTime,
				template: this.form.value.template,
			});
		}

		var hours = Math.abs(this.startDateTime - this.endDateTime) / 36e5;
		console.log(hours);
		if (hours > 0 && hours <= 4) {
			this.isTimeSelectionValid = true;
		} else {
			this.isTimeSelectionValid = false;
		}
	}

	onDateSelectionChange(event, form, picker) {
		console.log(event);
		console.log(picker);
		this.startDateTime = form.value.startDateTime;
		this.endDateTime = form.value.endDateTime;
		var hours = Math.abs(this.startDateTime - this.endDateTime) / 36e5;
		console.log(hours);
		if (hours <= 4) {
			this.isTimeSelectionValid = true;
		} else {
			this.isTimeSelectionValid = false;
			if (this.startDateTime > this.endDateTime) {
				// this.isTimeSelectionValid = false;
				//showmessage for inverted dates
			}
		}
		if (this.determineIfLive() === true) {
			this.listeningToSignalRMessages = true;
		} else {
			this.listeningToSignalRMessages = false;
		}
	}

	determineIfLive(): boolean {
		let dateNow: any = new Date();
		let startDate = this.startDateTime;
		let endDate = this.endDateTime;
		let diffInSeconds = Math.abs(dateNow - endDate) / 1000;
		const minutes = Math.floor(diffInSeconds / 60);

		console.log("minutes", minutes);

		if (minutes <= 5 || endDate > dateNow) {
			return true;
		} else {
			return false;
		}
	}

	getDataForDateRange(configuration: {
		form?: any;
		stdIdList?: any;
		getObservationsForDefaultList?: boolean;
		timeBeforeToStart?: number;
	}) {
		//This logic is to prevent the playback start date end date being overridden by alarm date once its been retrieved once. They may want to chagne the timeline.

		let timeBack;
		let timebeofreToStart = configuration.timeBeforeToStart
			? configuration.timeBeforeToStart * 60 * 1000
			: 5 * 60 * 1000;
		if (
			this.dataObject?.breadcrumbTags?.length > 0 &&
			!this.playbackHasBeenLoadedOnce
		) {
			if (this.timeZoneType === "User Time") {
				this.startDateTime = new Date(
					this.dataObject?.breadcrumbTags[0].UserDateFull.valueOf() -
						timebeofreToStart
				);
			} else if (this.timeZoneType === "Site Time") {
				this.startDateTime = new Date(
					this.dataObject?.breadcrumbTags[0].SiteDateFull.valueOf() -
						timebeofreToStart
				);
			} else if (this.timeZoneType === "UTC Time") {
				this.startDateTime = new Date(
					this.dataObject?.breadcrumbTags[0].UTCDateFull.valueOf() -
						timebeofreToStart
				);
			}
			timeBack = 10;

			this.minutesOutCalculation("start", timeBack);
		} else if (
			this.widgetObject?.breadcrumbTags?.length > 0 &&
			!this.playbackHasBeenLoadedOnce
		) {
			if (this.timeZoneType === "User Time") {
				this.startDateTime = new Date(
					this.widgetObject?.breadcrumbTags[0].UserDateFull.valueOf() -
						timebeofreToStart
				);
			} else if (this.timeZoneType === "Site Time") {
				this.startDateTime = new Date(
					this.widgetObject?.breadcrumbTags[0].SiteDateFull.valueOf() -
						timebeofreToStart
				);
			} else if (this.timeZoneType === "UTC Time") {
				this.startDateTime = new Date(
					this.widgetObject?.breadcrumbTags[0].UTCDateFull.valueOf() -
						timebeofreToStart
				);
			}
			timeBack = 10;

			this.minutesOutCalculation("start", timeBack);
		} else if (
			this.inputAssetObject?.breadcrumbTags?.length > 0 &&
			!this.playbackHasBeenLoadedOnce
		) {
			if (this.timeZoneType === "User Time") {
				this.startDateTime = new Date(
					this.inputAssetObject?.breadcrumbTags[0].UserDateFull.valueOf() -
						timebeofreToStart
				);
			} else if (this.timeZoneType === "Site Time") {
				this.startDateTime = new Date(
					this.inputAssetObject?.breadcrumbTags[0].SiteDateFull.valueOf() -
						timebeofreToStart
				);
			} else if (this.timeZoneType === "UTC Time") {
				this.startDateTime = new Date(
					this.inputAssetObject?.breadcrumbTags[0].UTCDateFull.valueOf() -
						timebeofreToStart
				);
			}
			timeBack = 10;

			this.minutesOutCalculation("start", timeBack);
		} else {
			timeBack = configuration.form.value.timeBack;
		}
		this.chartSeriesData = [];
		this.chart = undefined;
		this.isLoading = true;

		if (this.determineIfLive() === true) {
			this.endDateTime = new Date();
			if (configuration.form !== undefined) {
				this.form.setValue({
					startDateTime: this.startDateTime,
					endDateTime: this.endDateTime,
					timeBack: timeBack,
					template: configuration.form.value.template,
				});
			} else {
				this.form.setValue({
					startDateTime: this.startDateTime,
					endDateTime: this.endDateTime,
					timeBack: timeBack,
					template: this.form.value.template,
				});
			}

			this.initializeDataAndMap({
				listenForSignalR: true,
				componentInitialization: false,
				idListFromTemplate: configuration.stdIdList,
				getObservationsForDefaultList:
					configuration.getObservationsForDefaultList,
			});
			this.listeningToSignalRMessages = true;
		} else {
			this.initializeDataAndMap({
				listenForSignalR: false,
				componentInitialization: false,
				idListFromTemplate: configuration.stdIdList,
				getObservationsForDefaultList:
					configuration.getObservationsForDefaultList,
			});
			this.listeningToSignalRMessages = false;
		}
	}

	submitForm(form) {
		console.log(form);
		console.log(this);
		this.startDateTime = form.value.startDateTime;
		this.endDateTime = form.value.endDateTime;
		this.initializeDataAndMap({});
	}

	addPointToPolyline(newPoint) {
		let obj = { lat: newPoint.Latitude, lng: newPoint.Longitude };
		let googleLatLng = new google.maps.LatLng(obj.lat, obj.lng);
		let markerObj: any = {
			position: googleLatLng,
			// title: newPoint.speed,
			map: this.mapForBreadcrumbs,
			icon: {
				url: "",
			},
		};
		markerObj.icon.url = this.mapsService.returnPlaybackMarker(
			newPoint.highestAlarm
		);

		newPoint.marker = new google.maps.Marker(markerObj);

		let foundSpeedName = this.chartWidgetSeries.find((series) => {
			return (
				series.jbtStandardObservationName.toLowerCase().includes ===
				"speedometer"
			);
		});
		let foundSpeedNameFormatted = "";
		if (foundSpeedName !== undefined) {
			foundSpeedNameFormatted =
				foundSpeedName.jbtStandardObservationName.replaceAll(" ", "_");
		}
		// let PathStyle = new google.maps.Polyline({
		// 	//latLong from previous point, lat long to this point.
		// 	path: [
		// 		this.realData[this.realData.length - 1].marker.position,
		// 		googleLatLng,
		// 	],
		// 	strokeColor:
		// 		newPoint[foundSpeedNameFormatted] >= 0 &&
		// 		newPoint[foundSpeedNameFormatted] <= 5
		// 			? "#54A9FE"
		// 			: newPoint[foundSpeedNameFormatted] > 5 &&
		// 			  newPoint[foundSpeedNameFormatted] <= 10
		// 			? "#82C91E"
		// 			: newPoint[foundSpeedNameFormatted] > 10 &&
		// 			  newPoint[foundSpeedNameFormatted] <= 20
		// 			? "#FACA48"
		// 			: newPoint[foundSpeedNameFormatted] > 20 &&
		// 			  newPoint[foundSpeedNameFormatted] <= 40
		// 			? "#D32020"
		// 			: "black",
		// 	strokeOpacity: 1.0,
		// 	strokeWeight: 7,
		// 	map: this.mapForBreadcrumbs,
		// });
		// this.googlePolylines.push(PathStyle);
		let position = this.realData[this.realData.length - 1].marker.position;
		let title = this.realData[this.realData.length - 1].marker.title;
		let size = new google.maps.Size(15, 15);

		let icon = {
			url: this.mapsService.returnPlaybackMarker(
				this.realData[this.realData.length - 1].highestAlert
			),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(10, 10),
			scaledSize: size,
		};

		let content: string = "";
		this.gridSettings.columnsConfig.forEach((column) => {
			if (column.title == "Date") {
				let value = newPoint[column.field];
				content += `<div>${value}</div>`;
			} else if (column.field !== "Id") {
				let name = column.title;
				let value = newPoint[column.field];
				content += `<div> ${name}: ${value}</div>`;
			}
		});
		if (newPoint.highestAlert !== "N/A") {
			content += `<div>${this.concatAlertsForInfowindow(
				newPoint,
				true
			)}</div>`;
		}

		// let conAlerts = this.concatAlertsForInfowindow(newPoint);
		// if (conAlerts !== undefined) {
		// 	content = `		<div> Speed: ${newPoint.Speed.toString()}</div>
		// 	<div> Engine Speed: ${newPoint.EngineSpeed.toString()}</div>
		// 	<div> Fuel Level: ${newPoint.FuelLevel.toString()}</div>
		// 	<div> Power On: ${newPoint.PowerOn.toString()}</div>${this.concatAlertsForInfowindow(
		// 		newPoint
		// 	)}</div>`;
		// } else {
		// 	content = `		<div> Speed: ${newPoint.Speed.toString()}</div>
		// 	<div> Engine Speed: ${newPoint.EngineSpeed.toString()}</div>
		// 	<div> Fuel Level: ${newPoint.FuelLevel.toString()}</div>
		// 	<div> Power On: ${newPoint.PowerOn.toString()}</div>`;
		// }

		// newPoint.infoWindow = new google.maps.InfoWindow({
		// 	content: content,
		// });
		// google.maps.event.addListener(newPoint.marker, "click", () => {
		// 	this.openInfoWindow(newPoint);
		// });

		newPoint.Index = this.realData.length;
		newPoint.JavascriptDate = this.determineAlarmDate(
			new Date(newPoint.Date),
			this.siteObject.UTCTimeOffset
		);
		this.realData.push(newPoint);
		if (this.chart !== undefined) {
			console.log(this.chart.series);
			this.gridSettings.columnsConfig.forEach((column, index) => {
				if (column.field !== "Id" && column.title !== "Date") {
					let value = newPoint[column.field];
					if (value !== undefined) {
						let dataItem: any = [
							newPoint.JavascriptDate.getTime(),
							parseInt(value),
						];
						let seriesIndex = this.chart.series((series) => {
							return series.name.includes(column.title);
						});
						if (seriesIndex !== -1) {
							this.chart.series[seriesIndex].addPoint(
								[
									newPoint.JavascriptDate.getTime(),
									parseInt(value),
								],
								index !=
									this.gridSettings.columnsConfig.length - 1
									? false
									: true,
								false
							);
						}
					}
				}
			});
			// this.chart.series[0].addPoint(
			// 	[newPoint.JavascriptDate.getTime(), parseFloat(newPoint.Speed)],

			// 	false,
			// 	false
			// );
			// this.chart.series[1].addPoint(
			// 	[
			// 		newPoint.JavascriptDate.getTime(),
			// 		parseFloat(newPoint.EngineSpeed),
			// 	],

			// 	false,
			// 	false
			// );
			// this.chart.series[2].addPoint(
			// 	[
			// 		newPoint.JavascriptDate.getTime(),
			// 		parseFloat(newPoint.FuelLevel),
			// 	],

			// 	false,
			// 	false
			// );
			// this.chart.series[3].addPoint(
			// 	[
			// 		newPoint.JavascriptDate.getTime(),
			// 		parseFloat(newPoint.PowerOn),
			// 	],

			// 	true,
			// 	false
			// );
		}

		// this.gridSettings.gridData.data.pop();
		// this.gridSettings.gridData.data.unshift(signalRDataObject);
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.realData);
		}
		// this.realData = this.realData.splice();
		this.ref.detectChanges();
		this.newLatLngBounds.extend(obj);
	}

	onResized(event) {
		//here we will handle resizing the map.
	}

	executeDateTimeForm() {
		console.log("submission working");
	}

	evaluateTagValueForParseFloat(tagObject) {
		if (
			_.isNil(tagObject) ||
			(!_.isNil(tagObject) && _.isNil(tagObject.FinalValue))
		) {
			return "-";
		} else {
			return parseFloat(tagObject.FinalValue).toFixed(2);
		}
	}

	toggleMap(event) {
		this.showMap = event.checked;
	}
	newToggle(event) {
		this.showMap = event.map;
		this.showTable = event.table;
		if (this.legend) {
			this.showMapLegend = event.mapLegend;
			this.legend.hidden = !this.showMapLegend;
		}
		let chartConfigurationChanged =
			event.tooltip !== this.showTooltip ||
			this.showLegend !== event.legend ||
			this.showPlotLines !== event.plotLines ||
			this.showPlotBands !== event.plotBands ||
			this.showYAxisTitles !== event.yAxisTitles
				? true
				: false;
		if (chartConfigurationChanged) {
			this.showTooltip = event.tooltip;
			this.showLegend = event.legend;
			this.showPlotLines = event.plotLines;
			this.showPlotBands = event.plotBands;
			this.showYAxisTitles = event.yAxisTitles;
			this.toggleChartChange();
		}

		if (!this.hasMapBeenLoaded && this.showMap) {
			this.hasMapBeenLoaded = true;
			this.renderMap();
		}
		this.show24HrTime = event.show24HrTime;
	}

	toggleChartChange() {
		this.drawChart(Global.Theme === "light" ? false : true);
	}

	newIndex(event) {
		console.log(event);
		console.log(this.sliderValuePlayback);
		let previousSliderValue = this.sliderValuePlayback;
		let previousIndex = this.sliderValuePlayback - 1;
		if (this.hasMapBeenLoaded && this.showMap) {
			this.realData[this.sliderValuePlayback - 1].marker.setMap(null);
			// this.realData[this.sliderValuePlayback - 1].marker.setMap(null);
			let icon: any = {};
			let dataObj = this.realData[previousIndex];
			let position = new google.maps.LatLng(
				dataObj.Latitude,
				dataObj.Longitude
			);

			if (previousIndex === 0) {
				icon.url =
					"http://maps.google.com/mapfiles/ms/micons/green.png";
				icon.origin = new google.maps.Point(0, 0);
				icon.anchor = new google.maps.Point(10, 10);
			} else {
				icon.url = this.mapsService.returnPlaybackMarker(
					dataObj.highestAlert
				);

				icon.scaledSize = new google.maps.Size(15, 15);
				icon.origin = new google.maps.Point(0, 0);
				icon.anchor = new google.maps.Point(9, 9);
			}
			dataObj.marker = new google.maps.Marker({
				position: position,
				// title: dataObj.Speed.toString(),
				icon: icon,
				optimized: false,
				zIndex: Number(google.maps.Marker.MAX_ZINDEX),
				// zIndex: 1000002
			});
			dataObj.marker.setMap(this.mapForBreadcrumbs);
		}
		//this.slidervalueplayback is the previous value
		this.sliderValuePlayback = event;
		let index = this.sliderValuePlayback - 1;
		if (this.hasMapBeenLoaded && this.showMap) {
			this.realData[this.sliderValuePlayback - 1].marker.setMap(null);
			let icon: any = {};
			let dataObj = this.realData[index];
			let position = new google.maps.LatLng(
				dataObj.Latitude,
				dataObj.Longitude
			);

			if (index === 0) {
				icon.url =
					"http://maps.google.com/mapfiles/ms/micons/green.png";
			} else {
				icon.url =
					"http://maps.google.com/mapfiles/ms/micons/orange.png";
			}
			dataObj.marker = new google.maps.Marker({
				position: position,
				// title: dataObj.Speed.toString(),
				icon: icon,
				optimized: false,
				zIndex: Number(google.maps.Marker.MAX_ZINDEX),
			});
			dataObj.marker.setMap(this.mapForBreadcrumbs);

			this.panToMarker(
				this.realData[this.sliderValuePlayback - 1],
				this.sliderValuePlayback - 1
			);
			// this.openInfoWindow(this.realData[this.sliderValuePlayback - 1]);
			// this.realData.forEach((dataObj, index) => {
			// 	let mapInstance = dataObj.infoWindow.getMap();
			// 	if (mapInstance !== null) {
			// 		this.closeInfoWindow(dataObj);
			// 	}
			// });
		}

		if (
			this.materialParentTabSelected?.textLabel === "Chart" &&
			this.chart !== undefined
		) {
			this.showChartTooltip();
		}

		if (this.materialParentTabSelected?.textLabel === "Details") {
			this.stepper.selectedIndex = this.sliderValuePlayback - 1;
			console.log(this.stepsOfStepper);
			this.stepsOfStepper._results[
				this.sliderValuePlayback - 1
			].nativeElement.scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "nearest",
			});

			// let element =
			// 	document.getElementById('stepperStep' + this.currentDataIndex);
			// 	console.log(element);
		}
	}

	showChartTooltip() {
		this.programticallyShowingTooltip = true;
		let playbackTimeout = setTimeout(() => {
			this.programticallyShowingTooltip = false;
			clearTimeout(playbackTimeout);
		}, 1000);
		// if(this.showTooltip){
		let currentRealData = this.realData[this.sliderValuePlayback - 1];
		let dateInMs = currentRealData.Date.getTime();
		let indexForPoint = 0;

		let closest = this.chart.series[0].points.reduce(
			(prev: any, curr: any) => {
				return Math.abs(curr.x - dateInMs) < Math.abs(prev.x - dateInMs)
					? curr
					: prev;
			}
		);
		let foundIndex = this.chart.series[0].points.findIndex((point: any) => {
			return point.x === closest.x;
		});
		this.chart.tooltip.hide();

		this.chart.series.forEach((series: any, index: any) => {
			// if (index === 0) {
			if (
				series.points?.length > 0 &&
				series.points[foundIndex] !== undefined &&
				!series.name.includes("Navigator")
			) {
				// console.log(series.points);
				series.points[foundIndex]?.onMouseOver();
			}
			// }
		});
		// }
	}

	closeInfoWindow(dataObj) {
		// this.selectedRowArray = [];
		// dataObj.infoWindow.setMap(this.mapForBreadcrumbs);
		dataObj.infoWindow.close(this.mapForBreadcrumbs, dataObj.marker);
		// this.selectedRowArray.push(dataObj.Id);
		// setTimeout(() => {
		// 	const scrollTo = document.querySelector('.k-state-selected');
		// 	if (scrollTo) {
		// 		scrollTo.scrollIntoView();
		// 	}
		// });
	}

	// openedChange(event){
	// 	if(this.drawer2._opened === true && this.chart === undefined){
	// 		this.drawChart();
	// 	}
	// }

	matTabHasChanged(e, type) {
		if (type === "parent") {
			this.materialParentTabSelected = e.tab;
		}
		console.log(e);
		if (this.materialParentTabSelected?.textLabel === "Chart") {
			this.determineToDrawChart(false);
		}
	}

	onSliderChange(event) {
		if (
			event !== undefined &&
			event !== this.currentDataIndexForPowerOnSlider
		) {
			this.currentDataIndexForPowerOnSlider = event;

			console.log(this.sliderValue);
			let startDate = new Date(
				this.sliderDataForWeekSelection[
					this.currentDataIndexForPowerOnSlider
				][0]
			);

			this.startDateTime = startDate;

			// maxDate.setDate(maxDate.getDate() - 1)
			let hoursAhead = _.cloneDeep(this.startDateTime);

			hoursAhead.setMinutes(
				hoursAhead.getMinutes() + parseInt(this.form.value.timeBack)
			);

			this.endDateTime = hoursAhead;
			this.form.setValue({
				startDateTime: this.startDateTime,
				endDateTime: this.endDateTime,
				timeBack: parseInt(this.form.value.timeBack),
				template: this.form.value.template,
			});
			if (this.determineIfLive() === true) {
				this.listeningToSignalRMessages = true;
			} else {
				this.listeningToSignalRMessages = false;
			}
		}
	}

	formatLabel() {
		let date = new Date(
			this.sliderDataForWeekSelection[
				this.currentDataIndexForPowerOnSlider
			][0]
		).toLocaleString();

		return date;
	}

	static gridInit(grid: GridsterComponentInterface) {
		console.info("gridInit", grid);
	}

	static gridDestroy(grid: GridsterComponentInterface) {
		console.info("gridDestroy", grid);
	}

	gridSizeChanged(grid: GridsterComponentInterface) {
		// console.info('gridSizeChanged', grid);
	}

	itemChange(item: any) {
		Global.User.DebugMode && console.log("item changed = %O", item);
		let itemCopy = item;
		if (!itemCopy.Id) {
			//if itemId doesn't exist, should be new widget, so let itemInit handle it.
		} else {
			itemCopy.Width = itemCopy.cols;
			itemCopy.Height = itemCopy.rows;
			itemCopy.Col = itemCopy.x;
			itemCopy.Row = itemCopy.y;
			this.dashboardService.addOrUpdateDashboardWidgetItem(itemCopy);
		}
	}

	itemResize(
		item: GridsterItem,
		itemComponent: GridsterItemComponentInterface
	) {
		Global.User.DebugMode && console.log(": itemResized %O", item);
		Global.User.DebugMode &&
			console.log(": itemComponent = %O", itemComponent);

		//this.widgetResizedEvent.emit(itemComponent);
	}

	itemInit(item: GridsterItem) {
		// console.info("itemInit", item, itemComponent);
		let itemCopy = item;
		Global.User.DebugMode && console.log(": gridsterItem = %O", itemCopy);

		// if (!itemCopy.Id) {
		// 	//if itemId doesn't exist, should be new widget, so let itemInit handle it.
		// 	itemCopy.Id = this.dashboardId;
		// 	itemCopy.WidgetId = "";
		// 	itemCopy.Width = itemCopy.cols;
		// 	itemCopy.Height = itemCopy.rows;
		// 	itemCopy.Col = itemCopy.x;
		// 	itemCopy.Row = itemCopy.y;
		// 	Global.User.DebugMode &&
		// 		console.log(": itemCopy = %O", itemCopy);
		// 	this.dashboardService.addOrUpdateDashboardWidgetItem(itemCopy);
		// 	setTimeout(() => {
		// 		if (
		// 			this.canUserSeeWidget(
		// 				this.dashboardWidgets[this.dashboardWidgets.length - 1]
		// 			)
		// 		)
		// 			this.loadEditedOrCreatedWidget(
		// 				this.dashboardWidgets.length - 1
		// 			);
		// 	}, 100);
		// 	Global.User.DebugMode &&
		// 		console.log(
		// 			": this.dashboardWidgets = %O",
		// 			this.dashboardWidgets
		// 		);
		// }
	}

	itemRemoved(
		item: GridsterItem,
		itemComponent: GridsterItemComponentInterface
	) {
		console.info("itemRemoved", item, itemComponent);
		//logic for removing
	}

	static itemValidate(item: GridsterItem) {
		return item.cols > 0 && item.rows > 0;
	}
}
