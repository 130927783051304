import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Optional,
	ElementRef,
	ViewChildren,
	ViewContainerRef,
	QueryList,
	OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
	CompactType,
	DisplayGrid,
	GridsterConfig,
	GridsterItem,
	GridsterItemComponentInterface,
	GridType,
} from 'angular-gridster2';
import * as moment from 'moment';
import { AddTemplateToCanvasComponent } from 'projects/shared-lib/src/lib/components/add-template-to-canvas/add-template-to-canvas.component';
import { CanvasPopupSettingsComponent } from '../../components/canvas-popup-settings/canvas-popup-settings.component';
import { CanvasGsePopupSettingsComponent } from '../../components/canvas-gse-popup-settings/canvas-gse-popup-settings.component';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import _ from 'lodash';
import { UtilityService } from 'projects/shared-lib/src/lib/services/utility.service';
import { Observable, Subscription, Subject, fromEvent } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CanvasWidgetTemplatePopupSettingsComponent } from '../canvas-widget-template-popup-settings/canvas-widget-template-popup-settings.component';
import { IGlobal } from '../../_models/global.model';

@Component({
	selector: 'lib-canvas',
	templateUrl: './canvas.component.html',
	styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit, OnDestroy {
	@ViewChildren('canvasWidgetsLazyLoadingSelector', {
		read: ViewContainerRef,
	})
	canvasWidgetsLazyLoadingSelector!: QueryList<ViewContainerRef>;

	@Optional() @Input() widgetObject: any;
	options: any = {
		gridType: GridType.Fixed, //will set the columns to fixedColWidth and rows height will fit the space available
		itemInitCallback: this.itemInit.bind(this), //callback to call for each item when is initialized
		itemChangeCallback: this.itemChange.bind(this), //callback to call for each item when is changes x, y, rows, cols
		itemResizeCallback: this.itemResize.bind(this), //callback to call for each item when width/height changes
		compactType: CompactType.CompactUp,
		margin: 5,
		outerMargin: false,
		outerMarginTop: null,
		outerMarginRight: null,
		outerMarginBottom: null,
		outerMarginLeft: null,
		useTransformPositioning: false,
		mobileBreakpoint: 640,
		minCols: 1,
		maxCols: 60,
		height: '2000px',
		width: '2000px',
		minRows: 1,
		maxRows: 100,
		maxItemCols: 60,
		minItemCols: 1,
		maxItemRows: 100,
		minItemRows: 1,
		maxItemArea: 5000,
		minItemArea: 1,
		defaultItemCols: 1,
		defaultItemRows: 1,
		fixedColWidth: 60,
		fixedRowHeight: 60,
		setGridSize: false, //leave as true so that this displays properly on mac. Sets the grid height to the size of its child components.
		keepFixedHeightInMobile: false,
		keepFixedWidthInMobile: false,
		scrollSensitivity: 10,
		scrollSpeed: 20,
		enableEmptyCellClick: false,
		enableEmptyCellContextMenu: false,
		enableEmptyCellDrop: false,
		enableEmptyCellDrag: false,
		enableOccupiedCellDrop: false,
		emptyCellDragMaxCols: 50,
		emptyCellDragMaxRows: 50,
		ignoreMarginInRow: false,
		draggable: {
			enabled: true,
		},
		resizable: {
			enabled: true,
		},
		swap: false,
		pushItems: true,
		disablePushOnDrag: false,
		disablePushOnResize: false,
		pushDirections: {
			north: true,
			east: false,
			south: true,
			west: false,
		},
		pushResizeItems: false,
		displayGrid: DisplayGrid.Always,
		disableWindowResize: false,
		disableWarnings: false,
		scrollToNewItems: false,
	};
	pcaAssetTemplate: Array<GridsterItem>;
	canvasPopupInitializedSubscription: any;
	canvasGsePopupInitializedSubscription: any;
	canvasWidgetTemplatePopupInitializedSubscription: any;
	partsInfoByAssetModelId: Array<any>;
	showAssetModelTemplate: boolean;
	showAddWidgetTemplateButton: boolean;
	assetModelTemplateComponentsExists: boolean;
	assetTemplateInfo: any;
	DialogRef: any;
	canvasWidgetClickedSubscription: any;
	canvasWidgets: Array<any> = new Array();
	dashboardLocked: boolean;
	canvasTemplateName: any;
	canvasTemplatesObject: any = [];
	canvasTemplateTypeId: any;
	widgetMovementRecentlyChanged: boolean = false;
	public widgetHeight$: Observable<number>;
	public height: number = 2.5;
	public widgetResizedEvent: EventEmitter<any> = new EventEmitter();
	public global: IGlobal = Global;
	dataObject: any;
	widgetDetails: any = [];
	assetId: any;

	constructor(
		public dataService: DataService,
		public dialog: MatDialog,
		private utilityService: UtilityService,
		public elementRef: ElementRef
	) {}

	ngOnInit(): void {
		this.assetId = this.widgetObject ? this.widgetObject.WidgetAssetId : this.dataObject.widgetObject.WidgetAssetId? this.dataObject.widgetObject.WidgetAssetId : this.dataObject.widgetObject.widgetObject.AssetId ? this.dataObject.widgetObject.widgetObject.AssetId : this.dataObject.widgetObject.widgetObject.Id;

		this.showAssetModelTemplate = false;
		this.showAddWidgetTemplateButton = false;

		this.canvasTemplateTypeId = this.dataObject == undefined ? 1 : 2;

		if (this.dataObject?.widgetObject?.TemplateId != null) {
			this.assignCanvasWidget(this.dataObject.widgetObject.TemplateId);
		}

		this.widgetHeight$ = fromEvent(
			this.elementRef.nativeElement,
			'resize'
		).pipe(startWith(null), map(this.getHeight.bind(this)));

		if (this.widgetObject?.fromCanvasDesigner == 'fleetTemplate')
			this.canvasPopUpGSESettingsChanged(this.widgetObject.result);
		if (this.widgetObject?.fromCanvasDesigner == 'modelTemplate')
			this.canvasPopUpSettingsChanged(this.widgetObject.result);

		this.canvasGsePopupInitializedSubscription =
			this.dataService.canvasGsePopupInitialized$.subscribe(
				(data: any) => {
					if (data.WidgetId === this.widgetObject?.WidgetId || data.WidgetId === this.dataObject.widgetObject.WidgetId) {
						//initialize popup and subscribe to close event
						const canvasGsePopupSettingsModal = this.dialog.open(
							CanvasGsePopupSettingsComponent,
							{
								width: '70%',
								height: '60%',
								data: {
									CanvasTemplateId: this.canvasTemplatesObject?.Id,
									CanvasTemplateTypeId: this.canvasTemplateTypeId,
								},
							}
						);

						canvasGsePopupSettingsModal
							.afterClosed()
							.subscribe((result) => {
								this.canvasPopUpGSESettingsChanged(result);
							});
					}
				}
			);
		this.canvasPopupInitializedSubscription =
			this.dataService.canvasPopupInitialized$.subscribe((data: any) => {
				if (data.WidgetId === this.widgetObject.WidgetId) {
					//initialize popup and subscribe to close event
					const canvasPopupSettingsModal = this.dialog.open(
						CanvasPopupSettingsComponent,
						{
							width: '70%',
							height: '70%',
							// data: this is where your data will go for the list of asset models to populate the dropdown on the popup,
							//data: { assetModels: 'test information' },

							// panelClass: "no-overflow",
						}
					);

					canvasPopupSettingsModal
						.afterClosed()
						.subscribe((result) => {
							if (result) {
								this.canvasPopUpSettingsChanged(result);
							}
						});
				}
			});

	}

	canvasPopUpSettingsChanged(result: any) {
		this.showAssetModelTemplate = false;
		this.assetTemplateInfo = result;
		this.partsInfoByAssetModelId = [];
		this.assetModelTemplateComponentsExists = false;
		//if AssetModelTemplate exists,retrieve the components
		if (result.assetModelTemplate.length > 0) {
			this.assetModelTemplateComponentsExists =
				true;
			let statement = `GetAssetModelTemplateComponentsByPCAOverviewTemplateId @PCAOverviewTemplateId = ${result.assetModelTemplate[0].Id}`;
			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {
					Global.User.DebugMode &&
						console.log(
							'API.GetAssetModelTemplateComponentsByPCAOverviewTemplateId data = %O',
							data
						);
					this.partsInfoByAssetModelId = data;
					this.pcaAssetTemplate = [];
					this.partsInfoByAssetModelId.forEach(
						(t) => {
							this.pcaAssetTemplate.push({
								Id: t.Id,
								cols: t.Width,
								rows: t.Height,
								y: t.Row,
								x: t.Col,
								JBTStandardObservationId:
									t.JBTStandardObservationId,
								PCAOverviewWidgetTypeId:
									t.PCAOverviewWidgetTypeId,
								PCAOverviewTemplateId:
									t.PCAOverviewTemplateId,
								AssetModelId:
									t.AssetModelId,
								OrganizationId:
									t.OrganizationId,
							});
						}
					);
					Global.User.DebugMode &&
						console.log(
							': this.pcaAssetTemplate = %O',
							this.pcaAssetTemplate
						);
					this.showAssetModelTemplate = true;
				});
		}
		//if AssetModelTemplate doesnt not exist,retrieve the default components
		else {
			let statement = `CanvasWidget_AddAssetModelTemplateRecord @AssetModelId =  ${this.assetTemplateInfo.assetModelId} ,@OrganizationId = ${this.assetTemplateInfo.currentUserOrganizationId}`;
			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {
					this.assetTemplateInfo.assetModelTemplate =
						data;
					let statement1 = `GetPartsAndAssociatedJBTStdsByAssetModelId @AssetModelId = ${result.assetModelId}`;
					this.dataService
						.SQLActionAsPromise(statement1)
						.then((data: any) => {
							Global.User.DebugMode &&
								console.log(
									'API.GetPartsAndAssociatedJBTStdsByAssetModelId data = %O',
									data
								);
							this.partsInfoByAssetModelId =
								data.filter(
									(a) =>
										a.Name !=
										'Alarms'
								);
							console.log(
								'partsInfoByAssetModelId without Alarms Component data = %O',
								this
									.partsInfoByAssetModelId
							);
							this.pcaAssetTemplate = [];
							this.partsInfoByAssetModelId.forEach(
								(t) => {
									this.pcaAssetTemplate.push(
										{
											cols: t.InitialHeight,
											rows: t.InitialWidth,
											y: 0,
											x: 0,
											label: t.Name,
											templateName:
												t.AngularDirectiveName,
											JBTStandardObservationId:
												t.JBTStandardObservationId,
											PCAOverviewWidgetTypeId:
												t.PCAOverviewWidgetTypeId,
											AssetModelId:
												t.AssetModelId,
											OrganizationId:
												this
													.assetTemplateInfo
													.currentUserOrganizationId,
										}
									);
									let statement1 = `CanvasWidget_AddAssetModelTemplateComponentsRecord @AssetModelId =  ${t.AssetModelId
										} ,@OrganizationId = ${this
											.assetTemplateInfo
											.currentUserOrganizationId
										} ,@PCAOverviewTemplateId = ${this
											.assetTemplateInfo
											.assetModelTemplate[0]
											.Id
										} ,@PCAOverviewWidgetTypeId =  ${t.PCAOverviewWidgetTypeId
										} ,@JBTStandardObservationId =  ${t.JBTStandardObservationId
										} ,@Height = ${t.InitialWidth
										} ,@Width = ${t.InitialHeight
										} ,@Row = ${0} ,@Col = ${0}`;
									this.dataService
										.SQLActionAsPromise(
											statement1
										)
										.then(
											(
												data: any
											) => {
												Global
													.User
													.DebugMode &&
													console.log(
														'API.CanvasWidget_AddAssetModelTemplateComponentsRecord data = %O',
														data
													);
											}
										);
								}
							);
							Global.User.DebugMode &&
								console.log(
									': this.pcaAssetTemplate = %O',
									this
										.pcaAssetTemplate
								);
							this.showAssetModelTemplate =
								true;
							this.assetModelTemplateComponentsExists =
								true;
						});
				});
		}

	}
	canvasPopUpGSESettingsChanged(result: any) {
		if (result && result.templateId == null) {
			var templateName = result.templateName.split("'").join("''");
			let statement =
				"API.Canvas_AddCanvasTemplateRecord " +
				"@AssetTypeId = " +
				result.assetTypeId +
				", @AssetModelId = " +
				result.assetModelId +
				", @AssetId = " +
				result.assetId +
				", @Name = '" +
				templateName +
				"', @CreatorUserId = " +
				result.userId +
				", @V2Compatible = " +
				true +
				", @IsSharableWithinOrganization = " +
				true +
				", @CanvasTemplateTypeId = " +
				this.canvasTemplateTypeId;

			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {

					this.canvasTemplateName = data[0].Name;
					this.showAddWidgetTemplateButton = true;

					if (this.widgetObject != null) {
						this.widgetObject.TemplateId = data[0].Id;
					}
					else if (this.dataObject != null) {
						this.dataObject.widgetObject.TemplateId = data[0].Id;
					}


				});
		} else if (result && result.templateId > 0) {
			//Canvas Template Exists, retrieve the canvas Template Widgets
			this.assignCanvasWidget(result.templateId);

			if (this.widgetObject != null) {
				this.widgetObject.TemplateId = result.templateId;
			}
			else if (this.dataObject != null) {
				this.dataObject.widgetObject.TemplateId = result.templateId;
			}
		}
	}

	assignCanvasWidget(templateId: any) {
		templateId = parseInt(templateId);
		this.canvasTemplatesObject =
			this.dataService.cache.canvasTemplatesObject[templateId];
		this.canvasTemplateName = this.canvasTemplatesObject.Name;
		this.canvasWidgets =
			this.dataService.cache.canvasWidgetTemplates.filter((p: any) => {
				return p.CanvasTemplateId == templateId;
			});
		if (this.canvasWidgets.length > 0) {
			for (const widget of this.canvasWidgets) {
				widget.cols = widget.Width;
				widget.rows = widget.Height;
				widget.x = widget.Col;
				widget.y = widget.Row;

				var canvasWidgetTemplateJBTStandardObservationsForUser = this.dataService.cache.canvasWidgetTemplateJBTStandardObservations.filter(
					(p: any) => {
						return (p.CanvasWidgetTemplateId == widget.Id);
					}
				);
				if (widget.AngularDirectiveName == 'tagGrid')
					canvasWidgetTemplateJBTStandardObservationsForUser = this.dataService.cache.canvasWidgetTemplateTagGridFilters.filter(
						(p: any) => {
							return (p.CanvasWidgetTemplateId == widget.Id);
						}
					);

				this.addWidgetDetails(widget, canvasWidgetTemplateJBTStandardObservationsForUser);

			}
		}
		this.showAddWidgetTemplateButton = true;
	}

	canvasWidgetTemplateToDelete(widget) {
		_.remove(this.canvasWidgets, function (n: any) {
			return (
				n.CanvasTemplateId === widget.CanvasTemplateId &&
				n.Id === widget.Id
			);
		});
		this.deleteCanvasWidgetTemplateRecord(widget);
	}

	addWidgetDetails(widget, canvasWidgetTemplateJBTStandardObservationsForUser) {
		if (widget.AngularDirectiveName == 'tagGraph' && canvasWidgetTemplateJBTStandardObservationsForUser?.length > 0) {

			let JBTStandardObservationDetail = '';

			canvasWidgetTemplateJBTStandardObservationsForUser.forEach((t) => {
				var JBTStandardObservationForUser = this.dataService.cache.jbtStandardObservations.filter(
					(so: any) => {
						return (so.Id == t.JBTStandardObservationId);
					}
				);
				JBTStandardObservationDetail = JBTStandardObservationDetail + t.JBTStandardObservationId + ' - ' + JBTStandardObservationForUser[0].Name + ' ; ';
			});

			let widgetDetails = {
				JBTStandardObservationDetail: JBTStandardObservationDetail,
			};
			this.widgetDetails[widget.Id] = widgetDetails;
		}
		else if (widget.AngularDirectiveName == 'tagGrid' && canvasWidgetTemplateJBTStandardObservationsForUser?.length > 0) {
			let options = '';
			let regexDesc = '';

			canvasWidgetTemplateJBTStandardObservationsForUser.forEach((t) => {
				if (t.FilterType == 'Kendo')
					options = options + ' Operator:' + t.Operator + ' Value: ' + t.FieldValue + ' Field: ' + t.ColumnField + ' ; ';
				if (t.FilterType == 'Regex') {
					options = options + ' Expression:' + t.Operator + ' Description: ' + t.FieldValue + ' Field: ' + t.ColumnField + ' ; ';
					regexDesc = regexDesc + ' - ' + t.FieldValue;
				}

			});

			let widgetDetails = {
				options: options,
				regexDesc: regexDesc,
			};
			this.widgetDetails[widget.Id] = widgetDetails;
		}
		else if (canvasWidgetTemplateJBTStandardObservationsForUser?.length > 0) {

			let JBTStandardObservationId = canvasWidgetTemplateJBTStandardObservationsForUser[0].JBTStandardObservationId;
			let MaxValue = canvasWidgetTemplateJBTStandardObservationsForUser[0].MaxValue;
			let HexColorWhenActive = canvasWidgetTemplateJBTStandardObservationsForUser[0].HexColor;

			var JBTStandardObservationForUser = this.dataService.cache.jbtStandardObservations.filter(
				(so: any) => {
					return (so.Id == canvasWidgetTemplateJBTStandardObservationsForUser[0].JBTStandardObservationId);
				}
			);
			let WidgetName = JBTStandardObservationForUser[0].Name;


			// Get the ValueWhenActive from the Asset object cuz JBTStandardObservationForUser[0].ValueWhenActive is not right --kd
			
			let ValueWhenActive = 1;
			if(this.assetId != null) {
				let tag = this.dataService.cache.assetsObject[this.assetId].Tags.filter(
					(t: any) => {
						return (t.JBTStandardObservationId == canvasWidgetTemplateJBTStandardObservationsForUser[0].JBTStandardObservationId);
					}
				);

				ValueWhenActive = tag.length > 0 ? tag[0].ValueWhenActive : 1;
			}

			let widgetDetails = {
				JBTStandardObservationId: JBTStandardObservationId,
				MaxValue: MaxValue,
				HexColorWhenActive: HexColorWhenActive,
				WidgetName: WidgetName,
				ValueWhenActive: ValueWhenActive,
			};
			this.widgetDetails[widget.Id] = widgetDetails;

		}
		else {
			this.widgetDetails[widget.Id] = undefined;
		}
	}

	openSettingsPopup(widget) {
		if(this.dataObject?.widgetObject?.selectedTags.length > 0)
			widget.selectedTags = this.dataObject.widgetObject.selectedTags;
		this.DialogRef = this.dialog.open(CanvasWidgetTemplatePopupSettingsComponent, {
			width: '50%',
			height: '70%',
			data: {
				CanvasWidgetTemplateId: widget.Id,
				CanvasWidgetTypeId: widget.CanvasWidgetTypeId,
				widget: widget,
			},
		});

		this.DialogRef.afterClosed().subscribe((result) => {
			console.log("after widget settings popup", result);
			var canvasWidgetTemplateJBTStandardObservationsForUser = result;
			this.addWidgetDetails(widget, canvasWidgetTemplateJBTStandardObservationsForUser);
		})

	}

	deleteCanvasWidgetTemplateRecord(widgetItem: any) {
		this.dataService
			.SQLActionAsPromise(
				'Canvas_DeleteCanvasWidgetTemplateRecord @CanvasTemplateId =  ' +
					widgetItem.CanvasTemplateId +
					', @Id = ' +
					widgetItem.Id +
					', @CanvasWidgetTypeId = ' +
					widgetItem.CanvasWidgetTypeId
			)
			.then((data: any) => {
				let message =
					widgetItem.Name + " - Canvas Widget has been deleted.";
				this.utilityService.showToastMessageShared({
					type: 'success',
					message: message,
				});

				// remove the CanvasWidgetTemplateRecord from the cache
				let index = this.dataService.cache.canvasWidgetTemplates.findIndex((template: any) => {
					return template.Id == widgetItem.Id;
				});
				this.dataService.cache.canvasWidgetTemplates.splice(index, 1)
				this.dataService.cache.canvasWidgetTemplatesObject[widgetItem.Id] = undefined;

			});
	}

	getHeight() {
		Global.User.DebugMode &&
			console.log(
				': this.elementRef.nativeElement.innerHeight = ' +
					this.elementRef.nativeElement.innerHeight +
					', this.elementRef.nativeElement.innerWidth = ' +
					this.elementRef.nativeElement.innerWidth
			);

		return this.elementRef.nativeElement.innerHeight / this.height;
	}

	openAddWidgetTemplateToSummaryTemplateModal() {

		this.DialogRef = this.dialog.open(AddTemplateToCanvasComponent, {
			width: '55%',
			height: '50%',
			data: {
				TemplateId: this.widgetObject ? this.widgetObject.TemplateId : this.dataObject?.widgetObject?.TemplateId,
				AngularDirectiveName: this.widgetObject ? this.widgetObject.AngularDirectiveName : this.dataObject?.widgetObject?.AngularDirectiveName,
			},
		});

		this.canvasWidgetClickedSubscription =
			this.DialogRef.componentInstance.canvasWidgetClicked.subscribe(
				(result) => {
					Global.User.DebugMode &&
						console.log(': widgetClicked = %O', result);
					let widget = _.clone(result);


					this.canvasWidgets.push(
						_.assign(widget, {
							cols: widget.InitialWidth,
							rows: widget.InitialHeight,
							x: widget.Col,
							y: widget.Row,
						})
					);
					this.utilityService.showToastMessageShared({
						type: 'success',
						message: 'Canvas Widget Successfully Added!',
						title: 'Canvas Widget',
					});
				}
			);
	}

	itemInit(item: GridsterItem) {
		if (!item.Id) {
			let statement = `Canvas_AddCanvasWidgetTemplateRecord @Name =  '${
				item.WidgetName
			}' ,@AngularDirectiveName = '${
				item.AngularDirectiveName
			}' ,@CanvasWidgetTypeId = ${
				item.CanvasWidgetTypeId
			} ,@CreatorUserId = ${item.CreatorUserId} ,@CanvasTemplateId = ${
				item.CanvasTemplateId
			} ,@Height = ${item.rows} ,@Width = ${item.cols} ,@Col = ${
				item.x
			} ,@Row = ${item.y} ,@V2Compatible = ${true}`;

			if(item.AngularDirectiveName == "gaugeDataDisplay") {
				//
				statement += ", @CanvasWidgetTemplateGaugeTypeId = 1";

			}
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				this.canvasWidgets[this.canvasWidgets.length - 1].Id = data[0].Id;
				this.canvasWidgets[this.canvasWidgets.length - 1].CanvasWidgetTemplateGaugeTypeId = 1;
			});
		}
	}

	itemChange(item: any) {
		//if AssetModelTemplate exists,save the changes
		if (this.assetModelTemplateComponentsExists === true) {
			let statement = `CanvasWidget_UpdateAssetModelTemplateComponentsRecord @AssetModelId =  ${item.AssetModelId} ,@OrganizationId = ${item.OrganizationId} ,@PCAOverviewTemplateId = ${this.assetTemplateInfo.assetModelTemplate[0].Id} ,@JBTStandardObservationId =  ${item.JBTStandardObservationId} ,@Height = ${item.rows} ,@Width = ${item.cols} ,@Row = ${item.y} ,@Col = ${item.x}`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						'API.CanvasWidget_UpdateAssetModelTemplateComponentsRecord data = %O',
						data
					);
			});
		}
		if (item.Id && this.showAddWidgetTemplateButton) {
			let statement = `Canvas_UpdateCanvasWidgetTemplateRecord @CanvasWidgetTemplateId =  ${item.Id} ,@CanvasTemplateId = ${item.CanvasTemplateId} ,@Height = ${item.rows} ,@Width = ${item.cols} ,@Row = ${item.y} ,@Col = ${item.x}`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						'API.Canvas_UpdateCanvasWidgetTemplateRecord data = %O',
						data
					);
			});
		}
	}

	itemResize(item: GridsterItem) {
		if (this.assetModelTemplateComponentsExists === true) {
			let statement = `CanvasWidget_UpdateAssetModelTemplateComponentsRecord @AssetModelId =  ${item.AssetModelId} ,@OrganizationId = ${item.OrganizationId} ,@PCAOverviewTemplateId = ${this.assetTemplateInfo.assetModelTemplate[0].Id} ,@JBTStandardObservationId =  ${item.JBTStandardObservationId} ,@Height = ${item.rows} ,@Width = ${item.cols} ,@Row = ${item.y} ,@Col = ${item.x}`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						'API.CanvasWidget_UpdateAssetModelTemplateComponentsRecord data = %O',
						data
					);
			});
		}
		if (item.Id && this.showAddWidgetTemplateButton) {
			let statement = `Canvas_UpdateCanvasWidgetTemplateRecord @CanvasWidgetTemplateId =  ${item.Id} ,@CanvasTemplateId = ${item.CanvasTemplateId} ,@Height = ${item.rows} ,@Width = ${item.cols} ,@Row = ${item.y} ,@Col = ${item.x}` ;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						'API.Canvas_UpdateCanvasWidgetTemplateRecord data = %O',
						data
					);
			});
		}
	}

	changedOptions(): void {
		if (this.options.api && this.options.api.optionsChanged) {
			this.options.api.optionsChanged();
		}
	}

	removeItem($event: MouseEvent | TouchEvent, item): void {
		$event.preventDefault();
		$event.stopPropagation();
		this.pcaAssetTemplate.splice(this.pcaAssetTemplate.indexOf(item), 1);
		if (this.assetModelTemplateComponentsExists === true) {
			let statement = `CanvasWidget_DeleteAssetModelTemplateComponentsRecord @AssetModelId =  ${item.AssetModelId} ,@OrganizationId = ${item.OrganizationId} ,@PCAOverviewTemplateId = ${this.assetTemplateInfo.assetModelTemplate[0].Id} ,@JBTStandardObservationId =  ${item.JBTStandardObservationId} ,@Height = ${item.rows} ,@Width = ${item.cols} ,@Row = ${item.y} ,@Col = ${item.x}`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						'API.CanvasWidget_DeletessetModelTemplateComponentsRecord data = %O',
						data
					);
			});
		}
	}

	// addItem(): void {
	//     this.dashboard.push({ x: 0, y: 0, cols: 1, rows: 1 });
	// }

	ngOnDestroy() {
		Global.User.DebugMode && console.log("ngOnDestroy invoked...");

		if(this.canvasGsePopupInitializedSubscription) {
			this.canvasGsePopupInitializedSubscription.unsubscribe()
		}
	  }
}
